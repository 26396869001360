import React from 'react';

import ModalLayout from './ModalLayout';
import { BtnOneLineBlue } from '../buttons/BtnOneLineBlue';

interface IBasicModalProps {
  closeModal: () => void;
  title: string;
  body?: string;
  btnText: string;
  onBtnClick?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
}

const BasicModal = ({
  closeModal,
  title,
  body,
  btnText,
  onBtnClick,
  onClose,
  children,
}: IBasicModalProps) => {
  const handleOnBtnClick = () => {
    closeModal();
    if (onBtnClick) {
      onBtnClick();
    }
  };

  return (
    <ModalLayout closeModal={closeModal} onClose={onClose}>
      <h1 className="mb-4 text-center text-lg font-bold">{title}</h1>
      {body && <p className="text-center">{body}</p>}
      {children && <div>{children}</div>}
      {onBtnClick && <BtnOneLineBlue text={btnText} onClickHandler={handleOnBtnClick} />}
    </ModalLayout>
  );
};

export default BasicModal;

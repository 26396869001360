// This constant determines the low balance limit for a specific locale
export const LOW_BALANCE_THRESHOLDS = {
  EUR: 5,
  CAD: 10,
  NOK: 50,
  THB: 200,
  INR: 400,
  USD: 10,
  ZAR: 80,
  NZD: 10,
};

export const PAY_AND_PLAY_IFRAME_URL = process.env.REACT_APP_PAY_AND_PLAY_IFRAME_BASE || '';
export const PAY_AND_PLAY_IFRAME_REDIRECT_URI =
  process.env.REACT_APP_PAY_AND_PLAY_IFRAME_REDIRECT_URI || '';
export const PAY_AND_PLAY_IFRAME_CLIENT_ID =
  process.env.REACT_APP_PAY_AND_PLAY_IFRAME_CLIENT_ID || '';
export const PAY_AND_PLAY_MESSAGE_ORIGIN = process.env.REACT_APP_PAY_AND_PLAY_MESSAGE_ORIGIN || '';

export const IS_HALLOWEEN = process.env.REACT_APP_IS_HALLOWEEN === 'true';

export const IS_SWEDEN = process.env.REACT_APP_BUILD_MODE === 'sweden';

export const REGILY_URL = process.env.REACT_APP_REGILY_URL || '';

export const IMAGES_BASE = process.env.REACT_APP_IMAGES_BASE || '';

export const CDN_BASE = process.env.REACT_APP_CDN_BASE || '';
export const CDN_IMAGES_PATH = '/luckydays/images';
export const CDN_IMAGES_GAMES_PATH = CDN_IMAGES_PATH + '/games';
export const CDN_IMAGES_COMMON_PATH = CDN_IMAGES_PATH + '/common';
export const CDN_PAYMENT_PROVIDERS_IMAGES_COMMON_PATH =
  CDN_IMAGES_PATH + '/common/payment-providers';

export const CASHIER_CSS = process.env.REACT_APP_CASHIER_CSS || '';
export const CASHIER_TRANSLATIONS = process.env.REACT_APP_CASHIER_TRANSLATIONS || '';

export const ONTARIO_CHECK_URL = process.env.REACT_APP_ONTARIO_CHECK_URL || '';

export const CDN_DATA = process.env.REACT_APP_CDN_DATA || '';
export const FLUID_OPERATOR_ID = process.env.REACT_APP_FLUID_OPERATOR_ID || '';
export const FLUID_OPERATOR_ID_REG = process.env.REACT_APP_FLUID_OPERATOR_ID_REG || '';
export const BFF_URL = process.env.REACT_APP_BFF_URL || '';
export const APP_VERSION = process.env.REACT_APP_SENTRY_RELEASE || '';

export const SPLASHTECH_URL = process.env.REACT_APP_SPLASHTECH_URL || '';
export const SPLASHTECH_CLIENT = process.env.REACT_APP_SPLASHTECH_CLIENT || '';

export const IMAGES = {
  gamesPlaceholder: `${CDN_BASE}/cdn-cgi/image/width=400,height=auto,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/sitting-cat.png`,
};

export const REGULATED_LOCALE = ['fi', 'fi-en', 'sv'];

export const DATE_FORMAT_CANADA_LOCALES = ['ca', 'ca-fr'];
export const DATE_FORMAT_REGULAR_LOCALES = ['en', 'za', 'nz'];

export const LD_SHOW_STATS_AND_LIMITS = 'LD_SHOW_STATS_AND_LIMITS';

export const REWARDS_REEL_GAMES = ['sugar-rush', 'buffalo-king-megaways', 'big-bass-bonanza'];

export type BuildModeType = 'normal' | 'sweden' | 'canada' | 'mexico';

export const BUILD_MODE: BuildModeType =
  (process.env.REACT_APP_BUILD_MODE as BuildModeType) || 'normal';

export const envIsNormal = (): boolean => {
  return BUILD_MODE === 'normal';
};

export const envIsSweden = (): boolean => {
  return BUILD_MODE === 'sweden';
};

export const envIsCanada = (): boolean => {
  return BUILD_MODE === 'canada';
};

export const envIsMexico = (): boolean => {
  return BUILD_MODE === 'mexico';
};

export const PNP_PROVIDER = 'trustly';

export const fluidOperatorId = (): string => {
  if (envIsMexico() || envIsCanada()) {
    return FLUID_OPERATOR_ID_REG;
  }
  return FLUID_OPERATOR_ID;
};

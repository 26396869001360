export const API_URLS = {
  CHANGE_PASSWORD: '/api/v2/user/changepassword',
  CHECK_USER_EMAIL_EXISTENCE: '/api/v2/auth/check-email',
  COMPLETE_USER: '/api/v2/user/complete-profile',
  COMPLIANCE_ONTARIO: '/api/v2/compliance/ontario',
  CREDIT_BONUS: '/api/v2/bonuses/credit',
  DELETE_BONUS: '/api/v2/bonuses/delete',
  FORGOT_PASSWORD: '/api/v2/auth/forgotpassword',
  GET_AGCO_USER_DETAILS: '/api/v2/user/agco',
  GET_COUNTRIES: '/api/v2/data/countries',
  GET_INDUSTRIES: '/api/v2/data/industries',
  GET_JACKPOTS: '/api/v2/jackpots',
  GET_LIMITS: '/api/v2/responsiblegaming/limits',
  GET_OCCUPATIONS: '/api/v2/data/occupations',
  GET_SUBDIVISIONS: '/api/v2/data/subdivisions',
  GET_ZIP_CODE_GEO: '/api/v2/data/zip-code-geo',
  REGISTER_AGCO: '/api/v2/register/agco',
  REGISTER_SHORT: '/api/v2/register/short',
  REGISTER_MEXICO: '/api/v2/register/mexico',
  SET_LIMITS: '/api/v2/responsiblegaming/limits/set',
  UPDATE_VERIFY_AGCO_USER_DETAILS: '/api/v2/user/agco/update-verify',
  USER_BONUSES: '/api/v2/bonuses',
  USER_CHANGE_EMAIL: '/api/v2/user/email',
  VALIDATE_EMAIL: '/api/v2/auth/validate/email',
  VALIDATE_EMAIL_MOBILE: '/api/v2/auth/validate/email-mobile',
  VALIDATE_MOBILE: '/api/v2/auth/validate/mobile',
  VERIFY_AGCO_USER_DETAILS: '/api/v2/user/agco/verify',
  GET_CONSENTS: '/api/v2/consents',
  SAVE_CONSENTS: '/api/v2/consents/save',
  UPDATE_CONTACT_INFO: '/api/v2/user/update-contact-info',
  AUTHENTICATE_WITH_CHALLENGE: '/api/v2/auth/authenticatewithchallenge',
};

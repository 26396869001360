import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/models/root-state.model';
import { isRegulatedLocale } from '@/shared/utility';
import { openModal } from '@/store/actions/modal';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { setLoginOpenedFrom } from '@/store/actions/uiActions';

interface OpenLogin {
  openedFrom: OpenedFrom;
}

export function useLogin(): { openLogin: (params: OpenLogin) => void } {
  const locale = useSelector((state: RootState) => state.common.locale);
  const dispatch = useDispatch();

  const openLogin = (params: OpenLogin) => {
    dispatch(setLoginOpenedFrom(params.openedFrom));
    triggerGTag(GTagEvents.open_registration, {
      openedFrom: params.openedFrom,
    });
    if (isRegulatedLocale(locale)) {
      dispatch(openModal('paynplay'));
    } else {
      dispatch(openModal('login'));
    }
  };

  return { openLogin };
}

import { RootState } from '@/models/root-state.model';
import { OpenedFrom } from '@/lib/gTagManager';

export const selectUiIsSingleGame = (state: RootState): boolean => state.ui.isSingleGame;

export const selectRegistrationOpenedFrom = (state: RootState): OpenedFrom | null =>
  state.ui.registrationOpenedFrom;

export const selectLoginOpenedFrom = (state: RootState): OpenedFrom | null =>
  state.ui.loginOpenedFrom;

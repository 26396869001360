import React from 'react';

import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import GameCardMC from './GameCardMC';
import { envIsMexico } from '@/constants/constants';
import GameCard from './GameCard';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import { GamesCategories } from '@/models/game-category.type';
import GamesSkeletonLoader from '@/components/shared/skeleton-loader/GamesSkeletonLoader';

interface GameListProps {
  games: GameCardVM[];
  gamesLoading: boolean;
  category: GamesCategories | string;
}

function GameList({ games, gamesLoading, category }: GameListProps) {
  if (gamesLoading) {
    return <GamesSkeletonLoader />;
  }
  return (
    <div className="flex justify-center">
      <div
        className={classNames(
          'w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6',
          'gap-y-12 gap-x-4 xs:gap-y-8 md:max-w-[1400px] px-[16px] md:px-[32px]',
        )}
      >
        {games.map(game => {
          return (
            <LazyLoad key={`${game.slug}-${game.gameId}-${category}`} offset={800}>
              {envIsMexico() ? (
                <GameCardMC game={game} category={category} />
              ) : (
                <GameCard game={game} category={category} />
              )}
            </LazyLoad>
          );
        })}
      </div>
    </div>
  );
}

export default GameList;

import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import LuckyDaysLogo from '@/components/UI/Logos/LuckyDaysLogo';
import CloseButton from '@/components/UI/Buttons/CloseButton';
import { BtnBlueLink } from '@/components/buttons/BtnBlueLink';
import { openModal } from '@/store/actions/modal';
import ForgotPasswordForm from '@/components/Modals/forgot-password/ForgotPasswordForm';
import ForgotPasswordSuccess from '@/components/Modals/forgot-password/ForgotPasswordSuccess';

const ForgotPasswordModal = ({ closeModal }: { closeModal: () => void }) => {
  // This component has two steps:
  // 1. Form step/screen
  // 2. Success step/screen
  const [isFormStep, setIsFormStep] = useState(true);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOnSuccess = () => {
    setIsFormStep(false);
  };

  return (
    <div id="e2e_forgotPasswordModal">
      <div className="max-w-[380px] rounded-md bg-offWhite p-8 relative">
        <CloseButton left onClick={closeModal} />
        <div>
          <div className="mb-8 mt-8 flex justify-center">
            <LuckyDaysLogo />
          </div>
          {isFormStep ? (
            <ForgotPasswordForm onSuccess={handleOnSuccess} />
          ) : (
            <ForgotPasswordSuccess />
          )}
        </div>
        <p className="mt-16 text-sm font-bold">
          {intl.formatMessage({ id: 'forgot.rememberedText' })}{' '}
          <BtnBlueLink
            onClickHandler={() => dispatch(openModal('login'))}
            text={intl.formatMessage({ id: 'forgot.rememberedLink' })}
          />
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;

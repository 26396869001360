import { useDispatch, useSelector } from 'react-redux';
import { isRegulatedLocale } from '@/shared/utility';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { useLocation } from 'react-router-dom';
import { isFastReg } from '@/utils/utils';
import { envIsMexico } from '@/constants/constants';
import { closeModal, openModal } from '@/store/actions/modal';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { useCallback } from 'react';
import { setRegistrationOpenedFrom } from '@/store/actions/uiActions';

export function useRegistration() {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const { pathname } = useLocation();

  const openRegistration = useCallback(
    (params: { openedFrom: OpenedFrom }): void => {
      // we set this OpenedFrom in the store to be able to track where the user started the Registration intent
      // so that we might bring him back to where he started upon registration success
      dispatch(setRegistrationOpenedFrom(params.openedFrom));
      triggerGTag(GTagEvents.open_registration, {
        openedFrom: params.openedFrom,
      });
      if (envIsMexico()) {
        dispatch(closeModal());
        absoluteNavigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }

      if (isRegulatedLocale(locale)) {
        dispatch(openModal('paynplay'));
        return;
      }

      if (isFastReg(locale)) {
        // Check if the user is currently on the homepage (scroll to the FastReg form) or
        // on any other page (redirect to the homepage)
        if (pathname === `/${locale}`) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          absoluteNavigate('/');
        }
        // Close any opened modal - e.g. login modal
        dispatch(closeModal());

        return;
      }

      // Ontario registration
      absoluteNavigate('/register');
    },
    [locale, pathname],
  );

  return { openRegistration };
}

import React, { Suspense } from 'react';

import {
  ArrowLeftEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  CogIcon,
  CreditCardIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useRebate } from '@/hooks/useRebate';
import { useRegistration } from '@/hooks/useRegistration';
import { isEmpty, isRegulatedLocale } from '@/shared/utility';
import { useCashierShow } from '@/features/cashier/useCashier';
import { selectAvailableBonusesWithContent } from '@/store/selectors/bonusesSelectors';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import CloseButton from '@/components/UI/Buttons/CloseButton';
import NotificationBadge from '@/components/UI/NotificationBadge';
import GameIcon from '@/components/UI/Icons/GameIcon';
import TrophyIcon from '@/components/UI/Icons/TrophyIcon';
import { selectUser } from '@/store/selectors/userSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import Logo from '@/components/shared/logos/Logo';
import { openModal } from '@/store/actions/modal';
import { useRewardReel } from '@/hooks/useRewardReel';
import { cdnImage } from '@/utils/image-utils';

const MenuDrawerUser = React.lazy(() => import('./MenuDrawerUser'));

const StyledMenuDrawer = styled.div`
  max-width: 400px !important;

  nav {
    padding: 64px 32px 0;

    @media (min-height: 600px) {
      padding: 100px 32px 0;
    }
  }

  ul {
    padding: 0;
    margin: 40px 0 0 0;

    li {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;

      &.margin-top {
        margin-top: 32px;
      }

      a,
      button {
        display: inline-flex;
        align-items: center;
        padding: 8px 0;
        border: none;
        background: none;
        text-decoration: none;
        color: ${props => props.theme.colors.blue3};
        transition: all 0.2s ease;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        text-align: left;
        cursor: pointer;

        &.small {
          font-size: 15px;
          opacity: 0.6;
          padding: 4px 0;
        }

        &.active {
          color: ${props => props.theme.colors.blue1};
        }

        svg {
          height: 22px;
          width: 22px;
          margin-right: 16px;
          color: black;
          opacity: 0.4;
        }

        .notification-badge {
          left: 100%;
          top: 3px;
        }
      }
    }
  }

  .button-wrapper {
    display: grid;
    padding: 32px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    @media (min-height: 600px) {
      padding: 32px 32px 64px;
    }

    .create-account {
      width: 100%;
      border-radius: 50vh;
      padding: 1em 1.5em;
      font-size: 18px;
      margin-top: 32px;
      color: #fff;
      background: ${props => props.theme.colors.blue1};
      border: none;
      font-weight: bold;
      transition: all 0.15s ease;
      -webkit-appearance: none;
      cursor: pointer;
      box-shadow:
        0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);

      &:hover {
        background: ${props => props.theme.colors.blue1Hover};
      }
    }
  }

  .user {
    background: none;
    border: none;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-column-gap: 16px;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-decoration: none;
    color: black;

    .angle {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      opacity: 0.2;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    @media (min-height: 600px) {
      padding: 32px;

      .angle {
        right: 26px;
      }
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50vh;
      background: ${props => props.theme.colors.blue1};
      color: white;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      display: grid;
      text-align: left;
      padding-right: 32px;

      span {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .balance {
        opacity: 0.6;
      }
    }
  }
`;

function MenuDrawer({ closeDrawer }: { closeDrawer: () => void }): JSX.Element {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const locale = useSelector(selectLocale);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const availableBonuses = useSelector(selectAvailableBonusesWithContent);
  const dispatch = useDispatch();
  const { showRebate } = useRebate();
  const { openRegistration } = useRegistration();
  const showCashier = useCashierShow();
  const { isEnabled: isRewardReelEnabled } = useRewardReel();

  const handleOpenCashier = () => {
    // as cashier is on a separate drawer we need to close the menu drawer before opening the cashier drawer
    closeDrawer();
    showCashier();
  };

  return (
    <StyledMenuDrawer className="drawer" role="presentation" aria-label="Menu">
      <CloseButton left onClick={closeDrawer} />
      <nav>
        <Logo />
        <ul>
          <li>
            <NavLink to="" onClick={closeDrawer} end>
              <HomeIcon /> {intl.formatMessage({ id: 'header.home' })}{' '}
              {!isEmpty(availableBonuses) && <NotificationBadge count={availableBonuses.length} />}
            </NavLink>
          </li>
          <li>
            <NavLink to="games" onClick={closeDrawer}>
              <GameIcon /> {intl.formatMessage({ id: 'header.games' })}
            </NavLink>
          </li>
          {isAuthenticated && (
            <>
              <li>
                <button
                  type="button"
                  className="cashier"
                  data-cy="menu-cashier-button"
                  onClick={() => {
                    triggerGTag(GTagEvents.sidenav_cashier_click);
                    handleOpenCashier();
                  }}
                >
                  <CreditCardIcon /> {intl.formatMessage({ id: 'header.cashier' })}
                </button>
              </li>
              {showRebate && (
                <li>
                  <NavLink to="lucky-rewards-club" onClick={closeDrawer}>
                    <TrophyIcon /> Lucky Rewards Club
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink to="settings" onClick={closeDrawer}>
                  <CogIcon /> {intl.formatMessage({ id: 'header.settings' })}
                </NavLink>
              </li>
            </>
          )}
          {isRewardReelEnabled && (
            <li>
              <NavLink
                to="rewards-reel"
                onClick={() => {
                  triggerGTag(GTagEvents.open_reward_reel, {
                    openedFrom: 'menu-drawer',
                  });
                  closeDrawer();
                }}
              >
                <img
                  height="20"
                  width="30"
                  className="h-[20px] w-[30px] invert mr-2 opacity-50"
                  src={cdnImage('reward-reel.png', {
                    width: '30',
                    height: '20',
                    dpr: '2',
                  })}
                  alt={intl.formatMessage({ id: 'rewardReel.title' })}
                />{' '}
                {intl.formatMessage({ id: 'rewardReel.title' })}
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="help" onClick={closeDrawer}>
              <QuestionMarkCircleIcon /> {intl.formatMessage({ id: 'header.help' })}
            </NavLink>
          </li>
          {isAuthenticated && (
            <li className="margin-top">
              <NavLink className="logout" onClick={closeDrawer} to="logout">
                <ArrowRightStartOnRectangleIcon /> {intl.formatMessage({ id: 'header.logout' })}
              </NavLink>
            </li>
          )}
          {!isAuthenticated && !isRegulatedLocale(locale) && (
            <li className="margin-top">
              <button
                type="button"
                className="login"
                onClick={() => {
                  triggerGTag(GTagEvents.sidenav_login_click);
                  closeDrawer();
                  dispatch(openModal('login'));
                }}
              >
                <ArrowLeftEndOnRectangleIcon className="scale-[-1]" />{' '}
                {intl.formatMessage({ id: 'header.login' })}
              </button>
            </li>
          )}
        </ul>
      </nav>
      {!isAuthenticated && (
        <div className="button-wrapper">
          <ul>
            <li className="margin-top">
              <NavLink className="small" onClick={closeDrawer} to="terms-and-conditions">
                {intl.formatMessage({ id: 'footer.termsLinkText' })}
              </NavLink>
            </li>
            <li className="small">
              <NavLink className="small" onClick={closeDrawer} to="privacy-policy">
                {intl.formatMessage({ id: 'footer.privacyPolicyLinkText' })}
              </NavLink>
            </li>
          </ul>
          <button
            type="button"
            className="create-account"
            onClick={() => {
              closeDrawer();
              openRegistration({ openedFrom: OpenedFrom.MenuDrawer });
            }}
          >
            {intl.formatMessage({ id: 'header.register' })}
          </button>
        </div>
      )}
      {isAuthenticated && user && !isEmpty(user) && (
        <Suspense fallback={<div />}>
          <MenuDrawerUser user={user} clickHandler={handleOpenCashier} />
        </Suspense>
      )}
    </StyledMenuDrawer>
  );
}

export default MenuDrawer;

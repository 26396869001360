import React from 'react';

import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import PromptMessage from '@/components/UI/PromptMessage';
import { BtnOneLineBlue } from '@/components/buttons/BtnOneLineBlue';
import EmailField from '@/components/form/email/EmailField';
import Form from '@/components/form/Form';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import BffApiService from '@/shared/bffApiService';

interface FormValues {
  email: string;
}

const initialValues: FormValues = {
  email: '',
};

const ForgotPasswordForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const intl = useIntl();
  const { request, loading, error } = useBffApiRequest();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'inputs.email.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
  });

  const handleOnSubmit = (values: FormValues) => {
    triggerGTag(GTagEvents.forgot_password_submit);
    request({
      apiMethod: BffApiService.forgotPassword,
      payload: { email: values.email },
      successCallback: () => onSuccess(),
    });
  };

  return (
    <>
      <p className="my-8 max-w-xs text-sm font-bold">
        {intl.formatMessage({ id: 'forgot.header' })}{' '}
        {intl.formatMessage({ id: 'forgot.subHeader' })}
      </p>
      {error && (
        <PromptMessage type="error" message={intl.formatMessage({ id: 'error.support' })} />
      )}
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {() => (
          <>
            <EmailField
              name="email"
              label={intl.formatMessage({ id: 'inputs.email' })}
              placeholder={intl.formatMessage({
                id: 'inputs.email.placeholder',
              })}
              className="border-[1px] border-gray-300 mb-8 [&>div:last-child]:text-red-500 focus-within:border-blue-blue"
            />
            <BtnOneLineBlue
              type="submit"
              isLoading={loading}
              text={intl.formatMessage({ id: 'forgot.sendButton' })}
            />
          </>
        )}
      </Form>
    </>
  );
};

export default ForgotPasswordForm;

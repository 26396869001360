import React, { lazy, Suspense, useEffect, useState } from 'react';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useXmas } from '@/hooks/useXmas';
import { setIpData, setLocale } from '@/store/actions/commonActions';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { getLocaleByNavigator, localeFromPath } from '@/utils/utils';
import { mapLocaleToPathLocale } from '@/utils/mappers';
import { logException } from '@/utils/logger';
import { getLocaleByCountryCode } from '@/shared/utility';
import { buildLocales } from '@/constants/locales';
import { envIsCanada, envIsMexico, envIsSweden, IS_HALLOWEEN } from '@/constants/constants';
import { fetchIpData } from '@/utils/ip-data.utils';
import GamesByProvider from '@/pages/games/categories/GamesByProvider';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { isLiveCasinoEnabled } from '@/utils/logic.utils';
import CategoryJackpotsGames from '@/pages/games/categories/CategoryJackpotsGames';
import Internationalisation from '@/features/internationalization/Internationalisation';
import Home from '@/pages/home/Home';
import Registration from '@/pages/registration/Registration';
import Games from '@/pages/games/Games';
import GamesLobby from '@/pages/games/lobby/GamesLobby';
import { NotFound } from '@/components/Pages/NotFound';
import Game from '@/pages/games/Game';
import Help from '@/components/Pages/Help/Help';
import AboutUs from '@/pages/about-us/AboutUs';
import { ResetPassword } from '@/components/Pages/ResetPassword';
import { Impersonate } from '@/components/Pages/Impersonate';
import { Migration } from '@/components/Pages/Migration';
import PrivacyPolicy from '@/components/Pages/PrivacyPolicy';
import TermsAndConditions from '@/components/Pages/TermsAndConditions';
import LuckyRewardsClub from '@/components/Pages/LuckyRewardsClub';
import { ChristmasCalendar } from '@/components/Pages/ChristmasCalendar';
import { PrivateRoutes } from '@/components/PrivateRoutes';
import SettingsHeader from '@/pages/settings/SettingsHeader';
import Details from '@/pages/settings/Details';
import Verify from '@/pages/settings/verify/Verify';
import GamingHistory from '@/pages/settings/history/GamingHistory';
import PaymentsHistory from '@/pages/settings/history/PaymentsHistory';
import { LoginHistory } from '@/components/LoginHistory';
import ActiveBonuses from '@/components/Pages/ActiveBonuses';
import Logout from '@/components/Pages/Logout';
import History from '@/pages/settings/history/History';
import App from '@/features/app/App';
import { BffGamesCategory } from '@lucky7ventures/bff-types';
import { MEXICO_FEATURES_RECORD } from '@/store/mexico/gamesCategoryNavStore';
import CategoryLastPlayedGames from '@/pages/games/categories/CategoryLastPlayedGames';
import CategoryRecommendedGames from '@/pages/games/categories/CategoryRecommendedGames';
import CategoryTableGames from '@/pages/games/categories/CategoryTableGames';
import CategoryNewGames from '@/pages/games/categories/CategoryNewGames';
import CategorySlotsGames from '@/pages/games/categories/CategorySlotsGames';
import CategoryAllGames from '@/pages/games/categories/CategoryAllGames';
import CategoryWinterGames from '@/pages/games/categories/CategoryWinterGames';
import CategoryHalloweenGames from '@/pages/games/categories/CategoryHalloweenGames';
import CategoryLiveCasinoGames from '@/pages/games/categories/CategoryLiveCasinoGames';
import RewardReelPage from '@/pages/reward-reel/RewardReelPage';
import { useRewardReel } from '@/hooks/useRewardReel';
import ResponsibleGamingPage from '@/pages/responsible-gaming/ResponsibleGamingPage';
import ResponsibleGamingTab from '@/pages/settings/responsible-gaming/ResponsibleGamingTab';

const MexicoMetadataCategoryGames = lazy(
  () => import('@/pages/games/categories/MexicoMetadataCategoryGames'),
);
const PaymentMethods = lazy(() => import('@/pages/payment-providers/PaymentMethods'));

const mexicoMetadataGamesFeaturesCategories = Object.keys(MEXICO_FEATURES_RECORD);

export function AppRouter(): JSX.Element | null {
  const locale = useSelector(selectLocale);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isXmasEnabled = useXmas();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [finalPath, setFinalPath] = useState<string | undefined>();
  const { isEnabled: isRewardsReelEnabled } = useRewardReel();

  useEffect(() => {
    if (envIsMexico()) {
      dispatch(setLocale('mx'));
      return;
    }

    if (envIsSweden()) {
      dispatch(setLocale('sv'));
      return;
    }

    if (envIsCanada()) {
      const localeByNavigator = getLocaleByNavigator();
      const pathLocale = localeFromPath(location);

      if (pathLocale) {
        dispatch(setLocale('ca-fr'));
        return;
      }

      dispatch(setLocale(localeByNavigator));
      return;
    }

    const pathLocale = localeFromPath(location);

    if (pathLocale) {
      // Locale is included in the url set locale in redux storage
      dispatch(setLocale(pathLocale));
    } else {
      // Locale is not included in the url -> get country code by IP and set locale in redux storage
      fetchIpData()
        .then(data => {
          const countryCode = data.country;
          let ipDataLocale = getLocaleByCountryCode(countryCode);

          // Check if the user should be redirected to /ca or /ca-fr when coming from a canadian IP
          if (countryCode === 'CA') {
            ipDataLocale = getLocaleByNavigator();
          }
          dispatch(setIpData(data));
          dispatch(setLocale(ipDataLocale));
        })
        .catch(e => {
          logException(new Error('App Routes Ip Data Error'), { error: e });
          // Fallback to /row if IP API Fails
          dispatch(setLocale('row'));
        });
    }
  }, []);

  useEffect(() => {
    if (!locale) {
      return;
    }
    // Check if the pathname starts with at least one of the locales
    // if not append the locale to the pathname
    const pathname = location.pathname;
    const pathLocale = localeFromPath(location);

    if (envIsCanada()) {
      if (locale === 'ca') {
        setFinalPath(pathname);
        return;
      }
    }

    // if the locale already starts with one of the LOCALES set it as a final path
    // also for Sweden and Mexico build we want to keep the path as it is
    if (envIsSweden() || envIsMexico() || pathLocale) {
      setFinalPath(pathname);
      return;
    }

    // in other cases, if the route doesn't start with locale we need to do a redirect to the locale
    const path = pathname === '/' ? '' : pathname;
    const combinedPath = `${mapLocaleToPathLocale(locale)}${path}${location.search}`;
    setFinalPath(combinedPath);
    navigate(combinedPath);
  }, [locale]);

  if (!finalPath) {
    return null;
  }

  return (
    <Internationalisation locale={locale}>
      <Suspense fallback={<div />}>
        <Routes>
          {buildLocales().map(locale => (
            <Route key={locale} path={locale} element={<App />}>
              <Route index element={<Home />} />
              {envIsCanada() && <Route path="register" element={<Registration />} />}
              <Route path="games" element={<Games />}>
                <Route index element={<GamesLobby />} />
                <Route path="last-played" element={<CategoryLastPlayedGames />} />
                <Route path="recommended" element={<CategoryRecommendedGames />} />
                <Route path="table-games" element={<CategoryTableGames />} />
                <Route path="new-games" element={<CategoryNewGames />} />
                <Route path="slots" element={<CategorySlotsGames />} />
                <Route path="jackpots" element={<CategoryJackpotsGames />} />
                <Route path="all-games" element={<CategoryAllGames />} />
                {isXmasEnabled && <Route path="winter-games" element={<CategoryWinterGames />} />}
                {IS_HALLOWEEN && (
                  <Route path="halloween-games" element={<CategoryHalloweenGames />} />
                )}
                {isLiveCasinoEnabled(locale, isAuthenticated) && (
                  <Route path="live-casino" element={<CategoryLiveCasinoGames />} />
                )}
                {envIsMexico() &&
                  mexicoMetadataGamesFeaturesCategories.map((key, index) => (
                    <Route
                      key={index.toString() + key}
                      path={key}
                      element={<MexicoMetadataCategoryGames category={key as BffGamesCategory} />}
                    />
                  ))}
                {envIsMexico() && <Route path={`:provider`} element={<GamesByProvider />} />}
                <Route element={<NotFound />} />
              </Route>
              <Route path="games/:game/:gameId" element={<Game />} />
              <Route path="help" element={<Help />} />
              {envIsMexico() && <Route path="payment-methods" element={<PaymentMethods />} />}
              {envIsMexico() && <Route path="about-us" element={<AboutUs />} />}
              <Route path="reset-password/:recoveryCode" element={<ResetPassword />} />
              <Route path="impersonate/:token" element={<Impersonate />} />
              {envIsCanada() && <Route path="migration/:token" element={<Migration />} />}
              <Route path="responsible-gaming" element={<ResponsibleGamingPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="lucky-rewards-club" element={<LuckyRewardsClub />} />
              {isXmasEnabled && (
                <Route path="lucky-days-of-december" element={<ChristmasCalendar />} />
              )}
              <Route path="cashier" element={<Home cashierDrawerOpen={true} />} />
              <Route path="promotions" element={null} />
              {isRewardsReelEnabled && <Route path="rewards-reel" element={<RewardReelPage />} />}
              {/* Private Routes */}
              <Route element={<PrivateRoutes />}>
                <Route path="settings" element={<SettingsHeader />}>
                  <Route index element={<Details />} />
                  <Route path="verify" element={<Verify />} />
                  <Route path="responsible-gaming" element={<ResponsibleGamingTab />} />
                  <Route path="history" element={<History />}>
                    <Route index element={<GamingHistory />} />
                    <Route path="payments" element={<PaymentsHistory />} />
                    {(envIsSweden() || envIsCanada()) && (
                      <Route path="login" element={<LoginHistory />} />
                    )}
                  </Route>
                  <Route path="active-bonuses" element={<ActiveBonuses />} />
                </Route>
                <Route path="logout" element={<Logout />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          ))}
          <Route path="*" element={<Navigate to={locale} />} />
        </Routes>
      </Suspense>
    </Internationalisation>
  );
}

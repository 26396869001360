import React, { useCallback, useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Spinner from '../UI/Spinner';
import { Button } from '../buttons/Button';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Form from '@/components/form/Form';
import SearchDropdownField from '@/components/form/search-dropdown/SearchDropdownField';
import RadioGroup, { RadioProps } from '@/components/form/radio/RadioGroup';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import {
  BffGetSowqResponse,
  BffPostSowqRequest,
  BffSowqAnswer,
  BffSowqQuestion,
  SowQuestionType,
} from '@lucky7ventures/bff-types';
import BffApiService from '@/shared/bffApiService';
import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';
import { kycGetDocuments } from '@/store/actions/kycActions';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

const validationForType = (questionType: SowQuestionType, intl: IntlShape) => {
  switch (questionType) {
    case SowQuestionType.MultipleAnswer:
      return Yup.array()
        .nullable()
        .test('is-number-array', intl.formatMessage({ id: 'input.error.required' }), value => {
          if (value === null || !Array.isArray(value)) {
            return false;
          }
          return value.length > 0;
        });
    case SowQuestionType.MultipleOrNoAnswer:
      return Yup.array().nullable();
    default:
      return Yup.number()
        .required(intl.formatMessage({ id: 'input.error.required' }))
        .typeError(intl.formatMessage({ id: 'input.error.required' }));
  }
};

const LabeledSearchField = ({
  name,
  label,
  options,
  optionsLabel,
}: {
  name: string;
  label: string;
  options: any;
  optionsLabel: string;
}) => {
  return (
    <div className="flex w-full flex-col space-y-2">
      <label className="mb-2 text-lg font-bold">{label}</label>
      <div className="rounded-lg border-2 border-blue-blue/20">
        <SearchDropdownField
          inputClassName="text-black"
          labelClassName="text-black"
          optionsClassName="border-[1px] border-blue-blue/30"
          name={name}
          label={optionsLabel}
          options={options}
        />
      </div>
    </div>
  );
};

const SowqFormFieldByType = ({ question }: { question: BffSowqQuestion }): JSX.Element => {
  if (question.type === SowQuestionType.SingleAnswer || question.type === SowQuestionType.Country) {
    return (
      <LabeledSearchField
        name={`${question.id}`}
        label={question.text}
        optionsLabel={question.text}
        options={mapAnswersToRadioProps(question.answers)}
      />
    );
  }

  return (
    <RadioGroup
      options={mapAnswersToRadioProps(question.answers)}
      name={`${question.id}`}
      label={question.text}
      multiple
    />
  );
};

const mapAnswersToRadioProps = (answers: BffSowqAnswer[]): RadioProps[] =>
  answers.map(answer => ({
    label: answer.text,
    value: `${answer.id}`,
  }));

export const SOWQModal = ({ closeModal }: { closeModal: () => void }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    request: getSowqRequest,
    loading: sowqLoading,
    data: sowqData,
    error: sowqError,
  } = useBffApiRequest<BffGetSowqResponse>();
  const {
    request: postSowqRequest,
    loading: postSowqLoading,
    error: postSowqError,
  } = useBffApiRequest<BffPostSowqRequest>();

  useEffect(() => {
    triggerGTag(GTagEvents.sowq_modal_opened);
    getSowqRequest({
      apiMethod: BffApiService.getKycSowq,
      successCallback: () => triggerGTag(GTagEvents.sowq_get_success),
      errorCallback: error =>
        triggerGTag(GTagEvents.sowq_get_error, {
          error: `bff-error-${error.code}`,
        }),
    });
  }, []);

  const initialValues: Record<string, number | null> | null = useMemo(
    () =>
      sowqData && sowqData.reduce((acc, question) => ({ ...acc, [`${question.id}`]: null }), {}),
    [sowqData],
  );

  const schema = useMemo(() => {
    if (!sowqData) {
      return Yup.object({});
    }

    return Yup.object(
      sowqData.reduce(
        (acc, question) => ({ ...acc, [`${question.id}`]: validationForType(question.type, intl) }),
        {},
      ),
    );
  }, [sowqData, intl]);

  const submitHandler = useCallback(
    values => {
      const mapAnswers = (answers: string | string[] | null): number[] | null => {
        if (Array.isArray(answers)) {
          return answers.map(a => parseInt(a));
        }
        if (typeof answers === 'string') {
          return [parseInt(answers)];
        }
        return [];
      };

      const payload: BffPostSowqRequest = Object.keys(values).map(questionId => {
        const answers = values[questionId];
        return {
          id: parseInt(questionId),
          answers: mapAnswers(answers),
          freeText: null,
        };
      });
      postSowqRequest({
        apiMethod: BffApiService.postKycSowq,
        payload,
        successCallback: () => {
          triggerGTag(GTagEvents.sowq_post_success);
          dispatch(kycGetDocuments());
          closeModal();
        },
        errorCallback: error =>
          triggerGTag(GTagEvents.sowq_post_error, {
            error: `bff-error-${error.code}`,
          }),
      });
    },
    [dispatch, closeModal],
  );

  return (
    <div className="relative w-full max-w-[570px] max-h-[90vh] lg:max-h-[670px] lg:max-w-xl p-6 pt-12 overflow-y-scroll overscroll-none no-scrollbar rounded-xl bg-white text-left">
      <button
        type="button"
        onClick={() => closeModal()}
        className="absolute top-0 left-0 rounded-br-xl p-2 text-gray-700 hover:bg-gray-200"
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
      <h2 className="text-3xl text-center">{intl.formatMessage({ id: 'verify.sow.header' })}</h2>
      <p className="mt-4 pb-4  border-b-[1px] border-black/30">
        {intl.formatMessage({ id: 'verify.sow.text' })}
      </p>
      {sowqError && (
        <p className="text-red-300 text-lg">
          {`${intl.formatMessage({ id: 'error.support' })} [${sowqError.code}]`}
        </p>
      )}
      {sowqLoading ? (
        <div className="w-full flex justify-center">
          <Spinner absolute={false} width={60} height={60} color="blue2" borderwidth={2} />
        </div>
      ) : (
        sowqData && (
          <Form initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
            {({ isValid }) => (
              <div className="flex flex-col space-y-16">
                {sowqData &&
                  initialValues &&
                  sowqData.map(question => {
                    return (
                      <div key={question.id}>
                        <SowqFormFieldByType question={question} />
                      </div>
                    );
                  })}
                <div className="mt-8 flex flex-col space-y-4">
                  {postSowqError && (
                    <p className="text-red-300 text-lg">
                      {`${intl.formatMessage({ id: 'error.support' })} [${postSowqError.code}]`}
                    </p>
                  )}
                  <Button
                    disabled={!isValid}
                    type="submit"
                    isLoading={postSowqLoading}
                    text={intl.formatMessage({ id: 'misc.submit' })}
                  ></Button>
                </div>
              </div>
            )}
          </Form>
        )
      )}
    </div>
  );
};

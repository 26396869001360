import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useRewardReel } from '@/hooks/useRewardReel';

const RewardReel = lazy(() => import('./RewardReel'));
const RewardReelHero = lazy(() => import('./RewardReelHero'));

function RewardReelPage(): JSX.Element {
  const intl = useIntl();
  const { url } = useRewardReel();
  return (
    <div>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'rewardReel.title' })}</title>
      </Helmet>
      <Suspense fallback={<div />}>
        {!url && <RewardReelHero />}
        <RewardReel />
      </Suspense>
    </div>
  );
}

export default RewardReelPage;

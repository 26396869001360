// LiveCasino is disabled for ZA, and AT if not authenticated
import { envIsNormal } from '@/constants/constants';

export const isLiveCasinoEnabled = (locale: string, isAuthenticated: boolean): boolean => {
  if (locale === 'at' && !isAuthenticated) {
    return false;
  }
  return locale !== 'za';
};

export const isSplashTechEnabled = (locale: string): boolean => {
  if (!envIsNormal()) {
    return false;
  }

  return ['ca', 'ca-fr'].includes(locale);
};

import React, { useEffect, useState } from 'react';

import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { AbsoluteNavigate } from '../AbsoluteNavigate';
import Spinner from '../UI/Spinner';
import CloseButton from '../UI/Buttons/CloseButton';
import Anchor from '../UI/Buttons/Anchor';
import { isEmpty } from '@/shared/utility';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { BUILD_MODE, IMAGES_BASE } from '@/constants/constants';
import { useLogin } from '@/hooks/useLogin';
import { useRegistration } from '@/hooks/useRegistration';
import { useCashierShow } from '@/features/cashier/useCashier';
import ApiService from '../../shared/apiService';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { getAvailableBonuses } from '@/store/actions/bonusesActions';
import { openModal } from '@/store/actions/modal';
import { OpenedFrom } from '@/lib/gTagManager';

const StyledChristmasModal = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 380px;
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.offWhite};

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    width: 380px;
  }

  .text {
    padding: 48px 32px;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 16px;

    img {
      width: 100%;
      max-width: 90px;
      margin: 0 auto 20px;
      height: auto;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 20px;

      svg {
        margin-right: 16px;
      }
    }

    p {
      font-weight: bold;
      opacity: 0.8;
    }

    .expired {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.red10};
      padding: 16px;
      font-size: 14px;
      border-radius: 8px;
      opacity: 1;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .not-eligible {
      margin-bottom: 0;
    }
  }

  .buttons {
    display: flex;
    flex-flow: column wrap;

    .cancel,
    .continue {
      flex: 100%;
      border: none;
      padding: 1em 1.5em;
      cursor: pointer;
      font-weight: bold;
      border-radius: 50vh;
      transition: all 0.2s ease;

      &:hover {
        box-shadow: ${props => props.theme.buttonShadowHover};
      }
    }

    .cancel {
      margin: 8px 0;
      background: rgba(0, 0, 0, 0.1);

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }

    .continue {
      box-shadow: ${props => props.theme.buttonShadow};
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.blue1};

      &:hover {
        background: ${props => props.theme.colors.blue1Hover};
      }
    }

    .modal-switch {
      margin-top: 16px;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 14px;

      button {
        font-weight: bold;
      }
    }

    .terms-and-conditions-link {
      font-weight: bold;
      margin-top: 16px;
      color: #777;
      font-size: 14px;

      &:hover {
        color: #333;
      }
    }
  }
`;

export function ChristmasModal({
  loggedIn,
  isXmasTester,
  header,
  description,
  buttonText,
  mobileLink,
  desktopLink,
  bonusExpired,
  termsHeader,
  termsContent,
  expiredText,
  futureText,
  bonusInFuture,
  bonusEligible,
  notEligibleText,
  image,
  isClaimable,
  bonusCode,
  closeModal,
  shortTerms,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const { openLogin } = useLogin();
  const { openRegistration } = useRegistration();
  const [isTodayBonus, setIsTodayBonus] = useState(false);
  const {
    request,
    loading: claimBonusLoading,
    error: claimBonusError,
    success: claimBonusSuccess,
  } = useApiV2Request();
  const showCashier = useCashierShow();

  useEffect(() => {
    setIsTodayBonus((!bonusInFuture && !bonusExpired) || isXmasTester);
  }, [bonusInFuture, bonusExpired, isXmasTester]);

  useEffect(() => {
    if (claimBonusSuccess) {
      dispatch(getAvailableBonuses());
      closeModal();
    }
  }, [claimBonusSuccess]);

  const claimHandler = () => {
    if (isClaimable) {
      request(ApiService.creditBonus, { bonusCode });
      return;
    }

    closeModal();
    if (isBrowser) {
      if (!isEmpty(desktopLink)) {
        absoluteNavigate(desktopLink);
      } else {
        setTimeout(() => {
          showCashier();
        }, 200);
      }
    } else if (!isEmpty(mobileLink)) {
      absoluteNavigate(mobileLink);
    } else {
      absoluteNavigate('/cashier');
    }
  };

  if (isBrowser && claimBonusSuccess && !isEmpty(desktopLink)) {
    return <AbsoluteNavigate to={desktopLink} />;
  }

  if (!isBrowser && claimBonusSuccess && !isEmpty(mobileLink)) {
    return <AbsoluteNavigate to={mobileLink} />;
  }

  return (
    <StyledChristmasModal className="text-modal">
      <CloseButton left onClick={closeModal} />
      <div className="text">
        {!isEmpty(image) && !bonusInFuture ? (
          <img
            src={`${IMAGES_BASE}/cdn-cgi/image/fit=cover,width=180,height=auto,format=auto,dpr=2${image}.png`}
            alt={image}
          />
        ) : (
          <img
            src={`${IMAGES_BASE}/cdn-cgi/image/fit=cover,width=180,height=auto,format=auto,dpr=2/christmas/christmas-icon.png`}
            alt="Christmas Icon"
          />
        )}
        {!bonusInFuture && (
          <div>
            <h1>{header}</h1>
            <p>{description}</p>
          </div>
        )}
        {bonusExpired && <p className="expired">{expiredText}</p>}
        {bonusInFuture && <p className="future">{futureText}</p>}
        {!bonusEligible && isTodayBonus && loggedIn && (
          <p className="not-eligible mt-2">{notEligibleText}</p>
        )}
        {claimBonusError?.error && (
          <p className="expired">
            {intl.formatMessage({ id: 'failedSignup.SomethingWentWrongTitle' })}
          </p>
        )}
        <div className="buttons">
          {((loggedIn && isTodayBonus && bonusEligible) || isXmasTester) && (
            <button
              data-cy="christmas-modal-login-button"
              disabled={claimBonusLoading}
              className="continue"
              type="button"
              onClick={() => claimHandler()}
            >
              {claimBonusLoading ? (
                <Spinner width={18} height={18} borderwidth={3} color="white" absolute={false} />
              ) : (
                buttonText
              )}
            </button>
          )}
          {((BUILD_MODE === 'canada' && isTodayBonus && !loggedIn) ||
            (isTodayBonus && loggedIn && bonusEligible)) && (
            <Anchor
              className="terms-and-conditions-link"
              onClick={() =>
                dispatch(
                  openModal('christmasBonusTerms', {
                    header: parse(termsHeader.toString()),
                    text: parse(termsContent.toString()),
                  }),
                )
              }
            >
              {intl.formatMessage({ id: 'bonusCard.termsLink' })}
            </Anchor>
          )}
          {!loggedIn && (
            <>
              <button
                data-cy="christmas-modal-login-button"
                className="continue mt-4"
                type="button"
                onClick={() => {
                  openLogin({ openedFrom: OpenedFrom.ChristmasModal });
                }}
              >
                {intl.formatMessage({ id: 'login.login' })}
              </button>
              <p className="modal-switch">
                {intl.formatMessage({ id: 'login.createAccountText' })}{' '}
                <Anchor
                  type="button"
                  onClick={() => {
                    openRegistration({ openedFrom: OpenedFrom.ChristmasModal });
                  }}
                >
                  {intl.formatMessage({ id: 'login.createAccountLink' })}
                </Anchor>
              </p>
            </>
          )}
          {loggedIn && isTodayBonus && bonusEligible && !isEmpty(shortTerms) && (
            <p className="mt-4 text-xs italic">{shortTerms}</p>
          )}
        </div>
      </div>
    </StyledChristmasModal>
  );
}

ChristmasModal.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  termsHeader: PropTypes.string.isRequired,
  termsContent: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  mobileLink: PropTypes.string.isRequired,
  desktopLink: PropTypes.string.isRequired,
  expiredText: PropTypes.string.isRequired,
  futureText: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  bonusExpired: PropTypes.bool.isRequired,
  bonusInFuture: PropTypes.bool.isRequired,
  bonusEligible: PropTypes.bool.isRequired,
  notEligibleText: PropTypes.string.isRequired,
  isClaimable: PropTypes.bool.isRequired,
  bonusCode: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  shortTerms: PropTypes.string.isRequired,
};

import React, { Suspense, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useSelector } from 'react-redux';
import { useExcludedGamesStore } from '@/store/excludedGamesStore';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { envIsCanada, envIsMexico, envIsNormal, envIsSweden } from '@/constants/constants';

const BonusTermsAndConditionsContentEn = React.lazy(
  () => import('./BonusTermsAndConditionsContentEn'),
);
const BonusTermsAndConditionsContentAe = React.lazy(
  () => import('./BonusTermsAndConditionsContentAe'),
);
const BonusTermsAndConditionsContentAt = React.lazy(
  () => import('./BonusTermsAndConditionsContentAt'),
);
const BonusTermsAndConditionsContentSv = React.lazy(
  () => import('./BonusTermsAndConditionsContentSv'),
);
const BonusTermsAndConditionsContentTh = React.lazy(
  () => import('./BonusTermsAndConditionsContentTh'),
);
const BonusTermsAndConditionsContentMx = React.lazy(
  () => import('./BonusTermsAndConditionsContentMx'),
);
const BonusTermsAndConditionsContentNo = React.lazy(
  () => import('./BonusTermsAndConditionsContentNo'),
);
const BonusTermsAndConditionsContentCa = React.lazy(
  () => import('./BonusTermsAndConditionsContentCa'),
);
const BonusTermsAndConditionsContentFr = React.lazy(
  () => import('./BonusTermsAndConditionsContentFr'),
);
const BonusTermsAndConditionsContentNz = React.lazy(
  () => import('./BonusTermsAndConditionsContentNz'),
);
const BonusTermsAndConditionsContentZa = React.lazy(
  () => import('./BonusTermsAndConditionsContentZa'),
);
const BonusTermsAndConditionsContentFi = React.lazy(
  () => import('./BonusTermsAndConditionsContentFi'),
);
const BonusTermsAndConditionsContentFiEn = React.lazy(
  () => import('./BonusTermsAndConditionsContentFiEn'),
);

const SpecificBonusTermsAndConditionsContent = ({
  locale,
  excludedGames,
}: {
  locale: string;
  excludedGames: string;
}): JSX.Element => {
  if (envIsSweden()) {
    return <BonusTermsAndConditionsContentSv excludedGames={excludedGames} />;
  }

  if (envIsMexico()) {
    return <BonusTermsAndConditionsContentMx excludedGames={excludedGames} />;
  }

  if (envIsCanada()) {
    switch (locale) {
      case 'ca-fr':
        return <BonusTermsAndConditionsContentFr excludedGames={excludedGames} />;
      default:
        return <BonusTermsAndConditionsContentCa excludedGames={excludedGames} />;
    }
  }

  switch (locale) {
    case 'ae':
      return <BonusTermsAndConditionsContentAe excludedGames={excludedGames} />;
    case 'fi':
      return <BonusTermsAndConditionsContentFi excludedGames={excludedGames} />;
    case 'fi-en':
      return <BonusTermsAndConditionsContentFiEn excludedGames={excludedGames} />;
    case 'za':
      return <BonusTermsAndConditionsContentZa excludedGames={excludedGames} />;
    case 'nz':
      return <BonusTermsAndConditionsContentNz excludedGames={excludedGames} />;
    case 'ca':
      return <BonusTermsAndConditionsContentCa excludedGames={excludedGames} />;
    case 'ca-fr':
      return <BonusTermsAndConditionsContentFr excludedGames={excludedGames} />;
    case 'no':
      return <BonusTermsAndConditionsContentNo excludedGames={excludedGames} />;
    case 'at':
      return <BonusTermsAndConditionsContentAt excludedGames={excludedGames} />;
    case 'th':
      return <BonusTermsAndConditionsContentTh excludedGames={excludedGames} />;
    default:
      return <BonusTermsAndConditionsContentEn excludedGames={excludedGames} />;
  }
};

const BonusTermsAndConditionsContent = (): JSX.Element => {
  const locale = useSelector(selectLocale);
  const { excludedGames, fetchExcludedGames } = useExcludedGamesStore(
    state => ({
      excludedGames: state.excludedGames,
      fetchExcludedGames: state.fetch,
    }),
    shallow,
  );

  useEffect(() => {
    const fileName = (() => {
      if (envIsMexico()) {
        return 'excluded_games_mx.json';
      }

      if (envIsCanada() || envIsSweden() || (envIsNormal() && ['fi', 'fi-en'].includes(locale))) {
        return 'excluded_games_reg.json';
      }

      return 'excluded_games.json';
    })();

    fetchExcludedGames(fileName);
  }, []);

  return (
    <div className="[&_ul]:mb-4 [&_ul]:list-disc [&_ul]:pl-4">
      <Suspense fallback={<div></div>}>
        <SpecificBonusTermsAndConditionsContent locale={locale} excludedGames={excludedGames} />
      </Suspense>
    </div>
  );
};

export default BonusTermsAndConditionsContent;

import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export const detachedDropdownFallbackValue = 'choose';

export interface DropdownValue {
  label: string;
  value: string;
}

export interface DetachedDropdownProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  options: DropdownValue[];
  label: string;
  labelClassName?: string;
  value: DropdownValue;
  onChange: (e: string) => void;
  'data-cy'?: string;
}

const DetachedDropdown = ({ onChange, options, value, ...props }: DetachedDropdownProps) => {
  return (
    <Listbox value={value.label} onChange={onChange}>
      <div className="relative" />
      <div className="static">
        <Listbox.Button
          data-cy={props['data-cy']}
          className={classNames(
            'font-bold backdrop-blur-lg',
            'rounded-3xl py-1.5 w-full px-3 max-w-full truncate',
            'flex gap-x-1 justify-center items-center min-h-fit',
            {
              'bg-blue-blue text-white': value.value !== detachedDropdownFallbackValue,
              'bg-gray-100': value.value === detachedDropdownFallbackValue,
            },
          )}
        >
          {value.label}
          <ChevronDownIcon
            width={20}
            height={20}
            fill={value.value !== detachedDropdownFallbackValue ? 'white' : 'black'}
          />
        </Listbox.Button>
        <Listbox.Options
          className={classNames(
            'absolute z-40 top-14 start-2 end-2 sm:inset-x-auto mx-auto overflow-y-scroll no-scrollbar',
            'max-h-[250px] sm:max-h-[350px] sm:max-w-[450px] sm:mx-auto border-blue-blue/80',
            'bg-white border-2 rounded-lg pt-2',
          )}
        >
          {options.map(option => (
            <Listbox.Option
              className="text-sm cursor-pointer mr-4"
              key={option.label + option.value}
              value={option.value}
            >
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default DetachedDropdown;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowScroll } from 'react-use';
import { useIntl } from 'react-intl';

import { useRegistration } from '@/hooks/useRegistration';
import { useLogin } from '@/hooks/useLogin';
import { isRegulatedLocale } from '@/shared/utility';
import { classNames, isFastReg } from '@/utils/utils';
import { envIsSweden } from '@/constants/constants';
import { useOntarioRegisterRouteCheck } from '@/hooks/useOntarioRegisterRouteCheck';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { sendEventToAppWrapper } from '@/utils/app-wrapper-utils';
import { openModal } from '@/store/actions/modal';

const AuthButton = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const { y } = useWindowScroll();
  const intl = useIntl();
  const dispatch = useDispatch();
  const locale = useSelector((state: any) => state.common.locale);
  const { openRegistration } = useRegistration();
  const { openLogin } = useLogin();
  const [showRegister, setShowRegister] = useState(true);
  const { pathname } = useLocation();

  const isOntarioRegisterRoute = useOntarioRegisterRouteCheck();

  useEffect(() => {
    // This helps to indicate if the background of the header and the buttons should change color
    if (y !== 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
    // This is to make the register button colored when scrolled down for sweden and finland
    if (isRegulatedLocale(locale)) {
      if (y > 500) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    }
  }, [y]);

  useEffect(() => {
    if (isFastReg(locale) && pathname === `/${locale}` && y < 500) {
      setShowRegister(false);
    } else {
      setShowRegister(true);
    }
  }, [y, pathname]);

  const handleOpenRegistration = () => {
    triggerGTag(GTagEvents.header_register_click);
    if (envIsSweden()) {
      dispatch(openModal('paynplayIframe', { amount: 0, login: true }));
      return;
    }

    openRegistration({ openedFrom: OpenedFrom.Header });
  };

  const handleLoginClick = () => {
    triggerGTag(GTagEvents.header_login_click);
    sendEventToAppWrapper(GTagEvents.header_login_click);
    openLogin({ openedFrom: OpenedFrom.Header });
  };

  if (isRegulatedLocale(locale)) {
    return (
      <button
        id="e2e_headerRegisterBtn"
        type="button"
        className={classNames(
          isScrolled
            ? 'md:bg-slate-900/10 md:text-slate-900 md:hover:bg-slate-900/20'
            : 'md:bg-white/20 md:text-white md:hover:bg-white/30',
          isScrolledDown &&
            '!hover:bg-cyan-600 !shadow:md !hover:shadow-lg !bg-cyan-500 !text-white',
          'h-10 rounded-full bg-slate-900/10 px-4 font-bold text-slate-900 transition hover:bg-slate-900/20 hover:shadow-lg',
        )}
        onClick={handleOpenRegistration}
      >
        {intl.formatMessage({
          id: envIsSweden() ? 'header.register.zeroDeposit' : 'header.register',
        })}
      </button>
    );
  }

  return (
    <>
      <button
        type="button"
        className={`h-10 rounded-full bg-slate-900/10 px-4 font-bold text-slate-900 transition hover:bg-slate-900/20 hover:shadow-lg ${
          isScrolled
            ? 'md:bg-slate-900/10 md:text-slate-900 md:hover:bg-slate-900/20'
            : 'md:bg-white/20 md:text-white md:hover:bg-white/30'
        }`}
        onClick={handleLoginClick}
      >
        {intl.formatMessage({ id: 'header.login' })}
      </button>
      {!isOntarioRegisterRoute && (
        <button
          id="e2e_headerRegisterBtn"
          type="button"
          className={classNames(
            `flex h-10 w-10 items-center justify-center rounded-full bg-cyan-500 font-bold text-white shadow-md transition
          duration-200 ease-in-out hover:bg-cyan-600 hover:shadow-lg md:w-auto md:px-4`,
            showRegister ? 'block' : 'hidden',
          )}
          onClick={handleOpenRegistration}
        >
          <span className="hidden md:block">{intl.formatMessage({ id: 'header.register' })}</span>
          <UserPlusIcon className="h-5 w-5 md:hidden" />
        </button>
      )}
    </>
  );
};

export default AuthButton;

import { isAndroid, isIOS, isMobile, isTablet, isWinPhone } from 'react-device-detect';
import {
  envIsCanada,
  envIsMexico,
  envIsSweden,
  PAY_AND_PLAY_IFRAME_CLIENT_ID,
  PAY_AND_PLAY_IFRAME_REDIRECT_URI,
  PAY_AND_PLAY_IFRAME_URL,
  REGULATED_LOCALE,
} from '@/constants/constants';
import { DeviceTypeId } from '@lucky7ventures/bff-types';

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const getLocaleByCountryCode = countryCode => {
  if (envIsSweden()) {
    return 'sv';
  }

  if (envIsMexico()) {
    return 'mx';
  }

  switch (countryCode) {
    case 'AT':
    case 'DE':
      return 'at';
    case 'CA':
      return 'ca';
    case 'NZ':
      return 'nz';
    case 'FI':
      return 'fi';
    case 'NO':
      return 'no';
    case 'TH':
      return 'th';
    case 'ZA':
      return 'za';
    case 'AE':
    case 'DZ':
    case 'BH':
    case 'DJ':
    case 'EG':
    case 'IQ':
    case 'JO':
    case 'KW':
    case 'LB':
    case 'LY':
    case 'MR':
    case 'MA':
    case 'OM':
    case 'QA':
    case 'SA':
    case 'SO':
    case 'SD':
    case 'PS':
    case 'TN':
    case 'YE':
    case 'KM':
    case 'SY':
      return 'ae';
    default:
      return 'row';
  }
};

export const checkDevice = () => {
  if (isTablet && isIOS) {
    return DeviceTypeId.iPad;
  }

  if (isMobile && isIOS) {
    return DeviceTypeId.iPhone;
  }

  if (isMobile && isAndroid) {
    return DeviceTypeId.AndroidPhone;
  }

  if (isWinPhone) {
    return DeviceTypeId.WindowsMobile8;
  }

  if (isTablet && isAndroid) {
    return DeviceTypeId.AndroidTablet;
  }

  return DeviceTypeId.Desktop;
};

export const getHomeTitleByLocale = locale => {
  switch (locale) {
    case 'row':
      return 'LuckyDays - Online Casino | Up to €1,000 Casino Bonus + 100 Free Spins';
    case 'fi':
      return 'LuckyDays - Nettikasino | Pelaa suosituimpia slotteja ja valitse yli 2200 pelin joukosta!';
    case 'no':
      return 'LuckyDays - Online Casino | Opptil 10.000 kr i innskuddsbonus + 100 freespins';
    case 'at':
      return 'LuckyDays - Online Casino | Bis zu 1.000 € Casino Bonus + 100 Freispiele';
    case 'nz':
      return 'LuckyDays - Online Casino | Up to $1,000 Casino Bonus + 100 Free Spins';
    case 'ca':
      return 'LuckyDays - Online Casino | Up to $1,500 Casino Bonus + 100 Free Spins';
    case 'ca-fr':
      return `LuckyDays - Casino en Ligne | Jusqu'à $1,500 Casino Bonus + 100 Tours Gratuits`;
    case 'za':
      return 'LuckyDays - Online Casino | Up to R15,000 Casino Bonus + 100 Free Spins';
    case 'ae':
      return 'LuckyDays - Online Casino | Up to $1,000 Casino Bonus + 100 Free Spins';
    case 'th':
      return 'LuckyDays - คาสิโนออนไลน์ รับโบนัสต้อนรับสูงสุดกว่า 6000บาท + 99 ฟรีสปิน!';
    case 'sv':
      return 'LuckyDays - Online Casino | Upp till 500kr insättningsbonus + 100 Free Spins';
    case 'mx':
      return 'LuckyDays - Casino en línea | Bono de casino de hasta $12,000 MXN + $200 MXN';
    default:
      return 'LuckyDays - Online Casino';
  }
};

export const getHomeMetaDescByLocale = locale => {
  switch (locale) {
    case 'fi':
      return 'Tervetulos LuckyDays -kasinolle! Rekisteröidy tänään ja löydä suosikki Slotti-, Jackpot-, Roulette ja Blackjack-pelisi.';
    case 'no':
      return 'Velkommen til LuckyDays Casino! Registrer deg i dag for å få velkomstbonusen din og finn dine favorittslots, jackpots, roulette og blackjack.';
    case 'at':
      return 'Willkommen bei LuckyDays Casino! Meld dich noch heute an und erhalte deinen Willkommensbonus und finde deine liebsten Slot-, Jackpot-, Roulette- und Blackjack-Spiele.';
    case 'ca-fr':
      return `Bienvenue sur LuckyDays Casino! Inscrivez-vous aujourd'hui pour collecter votre bonus de bienvenue et pour trouver vos jeux de Machines à Sous, Jackpot, Roulette et Blackjack favoris.`;
    case 'th':
      return 'ยินดีต้อนรับสู่คาสิโน ลัคกี้เดย์ สมัครฟรีวันนี้ รับโบนัสต้อนรับจัดเต็ม เล่นเกมสล็อต รูเล็ต แบล็คแจ๊ค และเกมโปรดของคุณอีกเพียบ!';
    case 'sv':
      return 'Välkommen till LuckyDays Casino! Registrera dig idag för att få din välkomstbonus och hitta dina favoritslots, jackpots, roulette och blackjack.';
    case 'mx':
      return '¡Bienvenido a LuckyDays Casino! Regístrate hoy para obtener tu bono de bienvenida y disfrutar de tus juegos favoritos, slots, blackjack, bingo, ruletas y más.';
    case 'row':
    case 'ca':
    case 'nz':
    case 'za':
    case 'ae':
    default:
      return 'Welcome to LuckyDays Casino! Sign up today to collect your welcome bonus and find your favourite Slot, Jackpot, Roulette and Blackjack games.';
  }
};

export const getGameTitleByLocale = (gameName, locale) => {
  switch (locale) {
    case 'row':
      return `Play ${gameName} at LuckyDays Casino | Up to €1,000 Casino Bonus + 100 Free Spins`;
    case 'fi':
      return `Pelaa ${gameName} -peliä LuckyDays -kasinolla`;
    case 'no':
      return `Spill ${gameName} hos LuckyDays Casino | Opptil 10.000 kr i innskuddsbonus + 100 freespins`;
    case 'at':
      return `Spiele ${gameName} im LuckyDays Casino | Bis zu 1.000 € Casino Bonus + 100
			Freispiele`;
    case 'nz':
    case 'ae':
      return `Play ${gameName} at LuckyDays Casino | Up to $1,000 Casino Bonus + 100 Free Spins`;
    case 'ca':
      return `Play ${gameName} at LuckyDays Casino | Up to $1,500 Casino Bonus + 100 Free Spins`;
    case 'ca-fr':
      return `Jouez à ${gameName} sur LuckyDays Casino | Jusqu'à $1,500 de Bonus de Casino + 100 Tours Gratuits`;
    case 'za':
      return `Play ${gameName} at LuckyDays Casino | Up to R15,000 Casino Bonus + 100 Free
            Spins`;
    case 'th':
      return `เล่นเกม ${gameName} ที่คาสิโน ลัคกี้เดย์ รับโบนัสต้อนรับกว่า 6,000 บาท + 99 ฟรีสปิน!`;
    case 'sv':
      return `Spela ${gameName} på LuckyDays Casino | Upp till 1000kr insättningsbonus + 100 Free Spins`;
    case 'mx':
      return `Juega ${gameName} en LuckyDays Casino | Bono de casino de hasta $12,000 MXN + $200 MXN efectivo`;
    default:
      return `${gameName} | LuckyDays Casino`;
  }
};

export const getGameMetaDescByLocale = (gameName, locale) => {
  switch (locale) {
    case 'fi':
      return `Pelaa ${gameName} -peliä LuckyDays -kasinolla. Rekisteröidy tänään ja löydä suosikki Slotti-, Jackpot-, Roulette ja Blackjack-pelisi. `;
    case 'no':
      return `Spill ${gameName} på LuckyDays Casino. Registrer deg i dag for å få velkomstbonusen din og velg fra vårt store utvalg av online casinospill.`;
    case 'at':
      return `Spiele ${gameName} im LuckyDays Casino. Meld dich noch heute an und erhalte deinen Willkommensbonus und wähle aus unserer großen Auswahl an Online Casino Spielen.`;
    case 'ca-fr':
      return `Jouez à ${gameName} sur LuckyDays Casino. Inscrivez-vous aujourd'hui pour collecter votre bonus de bienvenue et choisissez parmi notre large gamme de jeux de casino en ligne.`;
    case 'th':
      return `เล่นเกม ${gameName} at ที่คาสิโน ลัคกี้เดย์ สมัครฟรีวันนี้และรับโบนัสต้อนรับจัดเต็มพร้อมเลือกเล่นเกมคาสิโนของเราสิคะ`;
    case 'sv':
      return `Spela ${gameName} på LuckyDays Casino. Registrera dig idag för att få din välkomstbonus och välj bland vårat stora utval av online casinospel.`;
    case 'mx':
      return `Juega ${gameName} en LuckyDays Casino. Regístrate hoy para obtener tu bono de bienvenida y elige entre nuestra amplia gama de juegos de casino en línea`;
    case 'row':
    case 'ca':
    case 'nz':
    case 'za':
    case 'ae':
    default:
      return `Play ${gameName} at LuckyDays Casino. Sign up today to collect your welcome bonus and choose from our wide range of online casino games.`;
  }
};

export const getHtmlLangByLocale = locale => {
  switch (locale) {
    case 'no':
      return 'no';
    case 'fi':
      return 'fi-fi';
    case 'at':
      return 'de-AT';
    case 'nz':
      return 'en-nz';
    case 'ca':
      return 'en-ca';
    case 'ca-fr':
      return 'fr';
    case 'za':
      return 'en-za';
    case 'ae':
      return 'en-ae';
    case 'th':
      return 'th';
    case 'sv':
      return 'sv';
    case 'mx':
      return 'es-MX';
    case 'row':
    default:
      return 'en';
  }
};

export const payAndPlayIframeUrl = (
  currency,
  country,
  amount,
  affiliateTag,
  language,
  bonusCode,
  provider,
  userId,
) => {
  const options = {
    amount,
    currency,
    country,
    identity_provider: provider,
    client_id: PAY_AND_PLAY_IFRAME_CLIENT_ID,
    redirect_uri: PAY_AND_PLAY_IFRAME_REDIRECT_URI,
    'attributes[currency]': currency,
    'attributes[language]': language,
    'attributes[affiliateTag]': affiliateTag,
    'attributes[bonusCode]': bonusCode,
    enduser_id: userId,
  };

  const params = Object.keys(options).reduce((paramsAcc, key) => {
    const value = options[key];
    if (!value) {
      return paramsAcc;
    }

    return `${paramsAcc}&${key}=${encodeURIComponent(value)}`;
  }, '');

  return `${PAY_AND_PLAY_IFRAME_URL}?${params}`;
};

export const isRegulatedLocale = locale => {
  if (!locale) {
    return false;
  }

  return REGULATED_LOCALE.includes(locale);
};

export const isRegulatedMarket = locale => isRegulatedLocale(locale) || envIsCanada();

export const getGigError = errors => {
  if (!errors || errors.length === 0) {
    return null;
  }

  if (typeof errors === 'string' || errors instanceof String) {
    return {
      id: 7001,
      message: errors,
    };
  }

  const error = errors[0];

  return {
    id: error.ErrorCodeID,
    message: error.Message,
  };
};

export const getAxiosError = error => {
  if (error.response) {
    if (error.response.data && !isNaN(error.response.data.id)) {
      const errorId = error.response.data.id;
      return {
        id: errorId,
        message: error.response.data.message,
      };
    }

    if (error.response.data && error.response.data.message) {
      return {
        id: 0,
        message: error.response.data.message,
      };
    }

    if (error.response.status) {
      return {
        id: error.response.status,
        message: error.response.data,
      };
    }
  }

  if (error.message) {
    return {
      id: 7000,
      message: error.message,
    };
  }

  return {
    id: 7000,
    message: 'Unknown error',
  };
};

export function getGameLocale(locale) {
  switch (locale) {
    case 'at':
    case 'za':
    case 'nz':
    case 'ae':
    case 'ca':
    case 'row':
    case 'fi-en':
      return 'en';
    case 'ca-fr':
      return 'fr';
    default:
      return locale;
  }
}

export const mapLocaleToIetfBcp47Format = locale => {
  switch (locale) {
    case 'at':
      return 'de-DE';
    case 'za':
      return 'en-ZA';
    case 'nz':
      return 'en-NZ';
    case 'ca':
      return 'en-CA';
    case 'ca-fr':
      return 'fr-FR';
    case 'no':
      return 'nb-NO';
    case 'mx':
      return 'es-MX';
    case 'th':
      return 'th-TH';
    default:
      return 'en';
  }
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { useRegistration } from '@/hooks/useRegistration';
import { isRegulatedLocale } from '@/shared/utility';
import { WrapperPlatform } from '@/models/enum/wrapper-platform.enum';
import { setWrapperPlatform } from '@/store/actions/commonActions';
import { openDrawer } from '@/store/actions/drawer';
import { openModal } from '@/store/actions/modal';
import { OpenedFrom } from '@/lib/gTagManager';

const useSearchParams = (): void => {
  const { search } = useLocation();
  const [modal, setModal] = useState<string | null>(null);
  const [drawer, setDrawer] = useState<string | null>(null);
  const [platform, setPlatform] = useState<string | null>(null);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const locale = useSelector(selectLocale);
  const { openRegistration } = useRegistration();

  useEffect(() => {
    const params = new URLSearchParams(search);
    setModal(params.get('modal'));
    setDrawer(params.get('drawer'));
    setPlatform(params.get('platform'));
  }, [search]);

  useEffect(() => {
    if (drawer === 'search') {
      dispatch(openDrawer('search'));
    }
  }, [drawer]);

  useEffect(() => {
    if (platform === 'ios') {
      dispatch(setWrapperPlatform(WrapperPlatform.iOS));
    } else if (platform === 'android') {
      dispatch(setWrapperPlatform(WrapperPlatform.Android));
    } else {
      dispatch(setWrapperPlatform(WrapperPlatform.None));
    }
  }, [platform]);

  useEffect(() => {
    if (modal === 'login' && !isAuthenticated) {
      dispatch(openModal('login'));
    }

    if (modal === 'forgot' && !isAuthenticated) {
      dispatch(openModal('forgotPassword'));
    }

    if (locale && modal === 'register' && !isAuthenticated) {
      openRegistration({ openedFrom: OpenedFrom.UrlParams });
    }

    if (locale && modal === 'logged-out' && !isAuthenticated) {
      if (isRegulatedLocale(locale)) {
        openModal('paynplay', { loggedOut: true });
      } else {
        openModal('login', { loggedOut: true });
      }
    }
  }, [locale, modal]);
};

export default useSearchParams;

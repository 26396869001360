import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snow from 'react-snow-effect';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { isEmpty } from '@/shared/utility';
import ChristmasRibbon from '../UI/Icons/ChristmasRibbon';
import LuckyDaysLogo from '../UI/Logos/LuckyDaysLogo';
import Spinner from '../UI/Spinner';
import { wpApiUrl } from '@/utils/utils';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '@/constants/constants';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import {
  selectAvailableBonusesWithContent,
  selectAvailableBonusesFetched,
} from '@/store/selectors/bonusesSelectors';
import { BonusRedeemType } from '@lucky7ventures/bff-types';
import classNames from 'classnames';
import { selectUserIsXmasTester } from '@/store/selectors/userSelectors';
import { openModal } from '@/store/actions/modal';

const XMAS_PAGE_BG_DESKTOP = `${CDN_BASE}/cdn-cgi/image/fit=cover,width=1440,gravity=1x0,format=auto${CDN_IMAGES_COMMON_PATH}/christmas/calendar-2024-bg-desktop.jpg`;
const XMAS_PAGE_BG_MOBILE = `${CDN_BASE}/cdn-cgi/image/fit=cover,width=1440,gravity=1x0,format=auto${CDN_IMAGES_COMMON_PATH}/christmas/calendar-2024-bg-mobile.jpg`;
const CALENDAR_BG = `${CDN_BASE}/cdn-cgi/image/fit=cover,width=800,height=auto,gravity=1x0,format=auto${CDN_IMAGES_COMMON_PATH}/christmas/calendar-2024-bg-mobile.jpg`;

const StyledChristmasCalendar = styled.div`
  background: ${props => props.theme.colors.blue2};
  min-height: 100vh;
  padding: 100px 16px;
  background: ${props => props.theme.colors.blue2};
  position: relative;
  background-size: cover;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .header {
    color: white;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-bottom: 12px;

    .mirror-image {
      transform: scaleX(-1);
    }

    svg {
      color: white;
      width: 80px;
      height: 34px;

      @media (min-width: ${props => props.theme.breakpoints.medium}) {
        width: 140px;
        height: 60px;
      }

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        width: 120px;
        height: 60px;
      }
    }

    .text {
      display: inline-flex;
      flex-flow: column wrap;
      align-items: center;
      position: relative;
      margin: 0 22px;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        margin: 0 24px;
      }

      h1 {
        display: flex;
        color: white;
        font-size: 18px;
        text-align: center;
        position: relative;
        font-family: 'Karla', sans-serif;

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          font-size: 20px;
        }

        &:after {
          content: '•';
          position: absolute;
          top: 2px;
          left: -14px;
          font-size: 20px;
        }

        &:before {
          content: '•';
          position: absolute;
          top: 2px;
          right: -14px;
          font-size: 20px;
        }
      }

      .hide {
        display: none;
      }
    }
  }

  .calendar {
    z-index: 1;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(7, 1fr);
    position: relative;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    background-image: url('${CALENDAR_BG}');
    background-size: cover;
    border: 2px solid white;

    &:before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    & > *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    .door {
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.3);
      color: white;
      font-weight: bold;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s ease;

      &:last-child {
        grid-column: 1 / 6;
      }

      &:hover {
        border: 2px solid white;

        .date {
          opacity: 1;
        }
      }

      &.today {
        /* border: 6px solid white; */
        box-shadow:
          0 0 10px 5px #fff,
          0 0 20px 10px #ff0;

        .date {
          opacity: 1;
        }
      }

      &.disabled {
        &:hover {
          box-shadow: none;
        }
      }

      .date {
        display: inline-flex;
        align-items: center;
        font-size: 24px;
        opacity: 0.6;
        transition: opacity 0.15s ease;

        svg {
          height: 24px;
          width: 24px;
          margin-right: 4px;
        }

        @media (min-width: ${props => props.theme.breakpoints.small}) {
          font-size: 32px;

          svg {
            height: 32px;
            width: 32px;
            margin-right: 4px;
          }
        }
      }
    }
  }
`;

const DAYS = [...Array(31).keys()].map(k => k + 1);
const TODAY = new Date().getDate();

export function ChristmasCalendar() {
  const locale = useSelector(selectLocale);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isXmasTester = useSelector(selectUserIsXmasTester);
  const availableBonuses = useSelector(selectAvailableBonusesWithContent);
  const availableBonusesFetched = useSelector(selectAvailableBonusesFetched);
  const dispatch = useDispatch();
  const intl = useIntl();

  const openModalHandler = (modalProps, modalType) => dispatch(openModal(modalProps, modalType));

  const [bonuses, setBonuses] = useState(null);
  const [openBonusDay, setOpenBonusDay] = useState(null);

  const getBonusById = useCallback(
    bonusId => {
      if (!isEmpty(availableBonuses)) {
        return availableBonuses.find(bonus => bonus.bonusId.toString() === bonusId);
      }
    },
    [availableBonuses],
  );

  const mapBonuses = bonusData =>
    bonusData.reduce(
      (acc, bonus) => ({
        ...acc,
        [bonus.day]: bonus,
      }),
      {},
    );

  const doorClickHandler = useCallback(
    day => {
      setOpenBonusDay(null);

      const today = new Date().getDate();
      const bonus = bonuses[day];

      if (!bonus) {
        // TODO: this is not too useful to the user
        console.log('There is not a christmas bonus in WP for this day');
        return;
      }

      const bonusExpired = day < today;
      const bonusInFuture = day > today;

      let bonusEligible = false;
      let isClaimable = false;
      let bonusCode = '';

      if ((isAuthenticated && day === today) || (isAuthenticated && isXmasTester)) {
        const availableBonus = getBonusById(bonus.bonus_id);
        bonusEligible = !!availableBonus || bonus.open_for_all;
        if (availableBonus) {
          isClaimable = availableBonus.redeemTypeId === BonusRedeemType.BonusCode;
          // eslint-disable-next-line prefer-destructuring
          bonusCode = availableBonus.promoCode;
        }
      }

      openModalHandler('christmas', {
        loggedIn: isAuthenticated,
        isXmasTester,
        bonusEligible,
        bonusExpired,
        bonusInFuture,
        header: bonus.bonus_header || '',
        description: bonus.bonus_description || '',
        image: bonus.bonus_image || '',
        buttonText: bonus.bonus_button_text || '',
        buttonLink: bonus.bonus_button_link || '',
        termsHeader: bonus.terms_and_conditions_header,
        termsContent: bonus.terms_and_conditions_content,
        mobileLink: bonus.mobile_link,
        desktopLink: bonus.desktop_link,
        expiredText: bonus.date_expired_text,
        futureText: bonus.future_date_text,
        notEligibleText: bonus.not_eligible_text || 'Not eligible',
        shortTerms: bonus.short_terms || '',
        isClaimable,
        bonusCode,
      });
    },
    [bonuses, isAuthenticated, isXmasTester],
  );

  const openBonus = useCallback(
    day => {
      if (!isAuthenticated) {
        doorClickHandler(day);
        return;
      }

      if (!availableBonusesFetched || !bonuses) {
        setOpenBonusDay(day);
        return;
      }

      doorClickHandler(day);
    },
    [isAuthenticated, availableBonusesFetched, bonuses],
  );

  /* This effect is responsible for opening the modal when the user clicks on the bonus day, but only
	 after the available bonuses are fetched. This is important because it can happen that the user clicks
	  before the bonuses are loaded and might get the wrong information. */
  useEffect(() => {
    if (!openBonusDay || !availableBonusesFetched || !bonuses) {
      return;
    }

    doorClickHandler(openBonusDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBonusDay, availableBonusesFetched, bonuses]);

  useEffect(() => {
    axios
      .get(wpApiUrl(locale, `/christmas`))
      .then(response => {
        setBonuses(mapBonuses(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  }, [locale]);

  return (
    <StyledChristmasCalendar>
      <div className="absolute inset-0">
        <picture>
          <source media="(max-width: 760px)" srcSet={XMAS_PAGE_BG_MOBILE} />
          <img
            className="object-cover w-full h-full"
            alt="calendar-background"
            src={XMAS_PAGE_BG_DESKTOP}
          />
        </picture>
      </div>
      <Snow />
      <div className="header">
        <ChristmasRibbon />
        <div className="text">
          <LuckyDaysLogo />
          <h1>
            <span className="hide">LuckyDays</span>
            {intl.formatMessage({ id: 'winter.calendar.subtitle' })}
          </h1>
        </div>
        <div className="mirror-image">
          <ChristmasRibbon />
        </div>
      </div>
      <div className="calendar">
        {!bonuses && <Spinner width={64} height={64} borderwidth={3} color="white" absolute />}
        {bonuses &&
          DAYS.map(day => (
            <button
              type="button"
              className={classNames('door', {
                disabled: day > TODAY && !isXmasTester,
                today: day === TODAY,
              })}
              onClick={() => openBonus(day)}
              key={day}
            >
              <div className="date">{day}</div>
              {openBonusDay === day && (
                <Spinner width={64} height={64} borderwidth={3} color="white" absolute />
              )}
            </button>
          ))}
      </div>
    </StyledChristmasCalendar>
  );
}

import { Combobox, Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, PlayIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { classNames } from '@/utils/utils';
import { SearchPalettePreview } from './SearchPalettePreview';
import { selectAllGames, selectLastPlayedGames } from '@/store/selectors/gamesSelectors';
import { CDN_BASE, CDN_IMAGES_GAMES_PATH } from '@/constants/constants';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { OpenedFrom } from '@/lib/gTagManager';
import { useLogin } from '@/hooks/useLogin';

const pageSize = 25;

export function SearchPalette(): JSX.Element {
  const games = useSelector(selectAllGames);
  const lastPlayedGames = useSelector(selectLastPlayedGames);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [filteredGames, setFilteredGames] = useState<GameCardVM[]>([]);
  const [visibleGames, setVisibleGames] = useState<GameCardVM[]>([]);
  const absoluteNavigate = useAbsoluteNavigate();
  const scrollableDiv = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const inProgress = useRef(false);
  const totalPages = useRef(0);
  const currentPageRef = useRef(0);
  const { openLogin } = useLogin();
  const intl = useIntl();

  const handleScroll = (e: any) => {
    if (currentPageRef.current >= totalPages.current) {
      return;
    }

    const target = e.target;

    if (scrollableDiv && scrollableDiv.current === target) {
      const scrollTop = target.scrollTop;
      const remainingScrollHeight = target.scrollHeight - target.clientHeight - scrollTop;

      if (remainingScrollHeight <= 100 && !inProgress.current) {
        // This triggers when scroll is near the end (100px from the end)
        setCurrentPage(prev => prev + 1);
        inProgress.current = true;
      }
    }
  };

  useEffect(() => {
    function onKeydown(event: KeyboardEvent) {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setIsOpen(prev => !prev);
      }
    }

    window.addEventListener('keydown', onKeydown);
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('keydown', onKeydown);
      window.addEventListener('scroll', handleScroll, true);
    };
  }, []);

  useEffect(() => {
    currentPageRef.current = currentPage;
    if (currentPage === 0) {
      return;
    }
    const startIndex = currentPage * pageSize;
    const remainingItems = filteredGames.length - startIndex;
    const endIndex =
      remainingItems >= pageSize ? startIndex + pageSize : startIndex + remainingItems;
    setVisibleGames(prev => [...prev, ...filteredGames.slice(startIndex, endIndex)]);
    inProgress.current = false;
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(0);
    setVisibleGames(
      filteredGames.slice(0, filteredGames.length > pageSize ? pageSize : filteredGames.length),
    );
  }, [filteredGames]);

  useEffect(() => {
    if (!query) {
      setFilteredGames([]);
      return;
    }
    const results = games.filter(
      (game: GameCardVM) =>
        game.name.toLowerCase().includes(query.toLowerCase()) ||
        game.shortDesc.toLowerCase().includes(query.toLowerCase()),
    );
    setFilteredGames(results);
    totalPages.current = Math.ceil(results.length / pageSize);
  }, [query]);

  const openGame = (game: GameCardVM): void => {
    setIsOpen(false);

    if (!game.isDemoAvailable && !isAuthenticated) {
      openLogin({ openedFrom: OpenedFrom.GamesSearch });
    } else {
      absoluteNavigate(`/games/${game.slug}/${game.gameId}`);
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={() => setQuery('')}>
      <Dialog
        onClose={setIsOpen}
        className="fixed inset-0 z-[120] overflow-y-auto p-4 pt-[10vh] sm:pt-[25vh]"
      >
        <Transition.Child
          enter="duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/60" />
        </Transition.Child>
        <Transition.Child
          enter="duration-300 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox
            onChange={openGame}
            value={null}
            as="div"
            className="relative mx-auto max-w-3xl divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5"
          >
            {({ activeOption }) => (
              <>
                <div className="relative flex items-center">
                  <MagnifyingGlassIcon className="pointer-events-none absolute left-4 h-6 w-6 text-gray-500" />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pr-4 pl-12 text-gray-800 placeholder-gray-400 focus:ring-0"
                    placeholder={intl.formatMessage({ id: 'searchPalette.placeholder' })}
                    onChange={event => setQuery(event.target.value)}
                  />
                  {filteredGames.length > 0 && (
                    <span className="absolute right-4 text-sm font-bold text-gray-400">
                      {filteredGames.length}{' '}
                      {intl.formatMessage({ id: 'header.games' }).toLowerCase()}
                    </span>
                  )}
                </div>
                {((query === '' && isAuthenticated && lastPlayedGames.length > 0) ||
                  filteredGames.length > 0) && (
                  <div className="relative flex divide-x divide-gray-100">
                    <div
                      ref={scrollableDiv}
                      className={classNames(
                        'relative max-h-96 min-w-0 flex-auto overflow-y-auto p-2 scrollbar-hide sm:p-4',
                        activeOption && 'sm:h-96',
                      )}
                    >
                      {query === '' && isAuthenticated && (
                        <div className="mb-4 text-xs font-bold text-gray-500">
                          {intl.formatMessage({ id: 'searchPalette.lastPlayed' })}
                        </div>
                      )}
                      <Combobox.Options static hold className="p-0 text-sm">
                        {(query === '' && isAuthenticated ? lastPlayedGames : visibleGames).map(
                          (game: GameCardVM) => (
                            <Combobox.Option
                              value={game}
                              className="mb-0 cursor-pointer select-none"
                              key={game.gameId}
                            >
                              {({ active }) => (
                                <div
                                  className={`relative flex items-center justify-between space-x-1 rounded-lg p-2 ${
                                    active ? 'bg-gray-100' : 'bg-white'
                                  }`}
                                >
                                  <div className="flex items-center truncate">
                                    <img
                                      src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=24,height=30,format=auto,dpr=2${CDN_IMAGES_GAMES_PATH}/${game.slug}.jpg`}
                                      height={30}
                                      width={24}
                                      alt={game.name}
                                      className="mx-auto h-7 w-6 rounded-md bg-gray-300 text-transparent"
                                    />
                                    <div className="ml-3 truncate pr-14 text-gray-400">
                                      <span className="font-bold text-gray-900">{game.name}</span>{' '}
                                      <span className="text-gray-400">{game.shortDesc}</span>
                                    </div>
                                  </div>
                                  {active && (
                                    <span className="absolute right-2 inline-flex h-5 items-center rounded-full bg-cyan-500 pl-2 pr-1 text-xs font-bold text-white">
                                      {intl.formatMessage({ id: 'gameCard.play' })}{' '}
                                      <PlayIcon
                                        className="ml-1 h-4 w-4 text-cyan-100"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}
                            </Combobox.Option>
                          ),
                        )}
                      </Combobox.Options>
                    </div>
                    <SearchPalettePreview
                      game={activeOption}
                      openGame={openGame}
                      showLogin={!activeOption?.isDemoAvailable && !isAuthenticated}
                    />
                  </div>
                )}
                {query && filteredGames.length === 0 && (
                  <div className="px-6 pt-12 pb-14 text-center text-sm sm:px-14">
                    <ExclamationTriangleIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 mb-0 font-semibold text-gray-900">
                      {intl.formatMessage({ id: 'searchPalette.noGamesFound.header' })}
                    </p>
                    <p className="mt-2 text-gray-500">
                      {intl.formatMessage({ id: 'searchPalette.noGamesFound.text' })}
                    </p>
                  </div>
                )}
              </>
            )}
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}

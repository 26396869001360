import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isSplashTechEnabled } from '@/utils/logic.utils';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { SPLASHTECH_CLIENT, SPLASHTECH_URL } from '@/constants/constants';
import { selectIsDepositingUser } from '@/store/selectors/combined.selectors';
import { selectUserIsRewardReelTester } from '@/store/selectors/userSelectors';

export function useRewardReel(): { isEnabled: boolean; url: string | null } {
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);
  const isDepositingUser = useSelector(selectIsDepositingUser);
  const isRewardsReelTester = useSelector(selectUserIsRewardReelTester);

  return {
    isEnabled: isSplashTechEnabled(locale) && isRewardsReelTester,
    url:
      token && isDepositingUser && isRewardsReelTester
        ? `${SPLASHTECH_URL}?client=${SPLASHTECH_CLIENT}&game=SPIN_MACHINE&token=${token}`
        : null,
  };
}

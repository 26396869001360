import React from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { BtnOneLineBlue } from '@/components/buttons/BtnOneLineBlue';
import { openModal } from '@/store/actions/modal';

const ForgotPasswordSuccess = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <>
      <p className="my-8 max-w-xs text-sm font-bold">
        {intl.formatMessage({ id: 'forgot.sent.header' })}{' '}
        {intl.formatMessage({ id: 'forgot.sent.text' })}
      </p>
      <BtnOneLineBlue
        onClickHandler={() => dispatch(openModal('login'))}
        isLoading={false}
        text={intl.formatMessage({ id: 'forgot.loginButton' })}
      />
    </>
  );
};

export default ForgotPasswordSuccess;

import { createSelector } from 'reselect';
import { selectKycDocumentsError, selectKycDocumentsLoading } from './kycSelectors';
import { envIsSweden, fluidOperatorId } from '@/constants/constants';
import { selectLimitsError, selectLimitsLoading } from './limitsSelectors';
import { MissingUserData } from '@/models/enum/missing-user-data.enum';
import {
  selectDepositCount,
  selectUser,
  selectUserAlphaCode3,
  selectUserCurrency,
  selectUserId,
  selectWalletError,
  selectWalletLoading,
} from './userSelectors';
import { selectBtag, selectLocale } from './commonSelectors';
import { selectAuthToken, selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { mapLocaleToIetfBcp47Format } from '@/shared/utility';
import { FluidInitParams } from '@fluidpayments/types';

// This selector should return true if there is any part of crucial user data in the loading state
// Crucial User Data varies between BUILDS
export const selectIsCrucialUserDataLoading = createSelector(
  selectKycDocumentsLoading,
  selectLimitsLoading,
  selectWalletLoading,
  (kycDocumentsLoading, limitsLoading, walletLoading) => {
    if (envIsSweden()) {
      return kycDocumentsLoading || limitsLoading || walletLoading;
    }

    return kycDocumentsLoading;
  },
);

export const selectCrucialUserDataErrors = createSelector(
  selectKycDocumentsError,
  selectLimitsError,
  selectWalletError,
  (kycDocumentsError, limitsError, walletError): MissingUserData[] => {
    const missingUserData = [];
    if (envIsSweden()) {
      if (limitsError) {
        missingUserData.push(MissingUserData.DepositLimits);
      }
      if (walletError) {
        missingUserData.push(MissingUserData.Wallet);
      }
    }

    if (kycDocumentsError) {
      missingUserData.push(MissingUserData.Kyc);
    }
    return missingUserData;
  },
);

export const selectUserBtag = createSelector(selectBtag, selectUser, (btag, user) => {
  // user is by default an {} object so we need to make sure it has a Reference property
  return user && 'Reference' in user ? user.Reference : btag;
});

export const selectFluidConfig = createSelector(
  selectLocale,
  selectUserId,
  selectUserAlphaCode3,
  selectAuthToken,
  selectUserCurrency,
  (locale, userId, userAlpha3, token, currency): FluidInitParams | null => {
    // we want to return null until we get all the pieces of the fluids config
    if (!locale || !userId || !currency || !userAlpha3 || !token) {
      return null;
    }

    return {
      operatorId: Number(fluidOperatorId()),
      userId: userId.toString(),
      sessionId: token,
      locale: mapLocaleToIetfBcp47Format(locale),
      countryCode: userAlpha3,
      currencyCode: currency,
    };
  },
);

export const selectIsDepositingUser = createSelector(
  selectIsAuthenticated,
  selectDepositCount,
  (isAuthenticated, depositCount) => {
    return isAuthenticated && depositCount && depositCount > 0;
  },
);

import React, { useMemo } from 'react';

import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '@/constants/constants';
import { cdnImage } from '@/utils/image-utils';

interface FooterLogo {
  src: string;
  alt: string;
  href?: string;
  rel?: string;
  target?: string;
  isDisplayed: boolean;
}

const footerImgPath = (width: number, image: string): string => {
  return `${CDN_BASE}/cdn-cgi/image/fit=cover,width=${width},height=auto,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/footer/${image}`;
};

export function FooterLogos({
  isCanada = false,
  isMexico = false,
  isSweden = false,
  isNormal = false,
  locale = 'row',
  isInAppWrapper,
}: {
  locale?: string;
  isCanada?: boolean;
  isMexico?: boolean;
  isSweden?: boolean;
  isNormal?: boolean;
  isInAppWrapper?: boolean;
}): JSX.Element {
  const visibleIcons: FooterLogo[] = useMemo(() => {
    const icons = [
      {
        src: footerImgPath(180, 'secure-payments-new.png'),
        alt: 'Secure Payments',
        isDisplayed:
          ['ca', 'ca-fr', 'row', 'ae', 'no', 'nz', 'th', 'za', 'at'].includes(locale) || isCanada,
      },
      {
        src: cdnImage('pago%20seguro.png', {
          path: '/luckydays/images/email',
          width: '180px',
          height: 'auto',
        }),
        alt: 'Payment Secure',
        isDisplayed: isMexico,
      },
      {
        src: footerImgPath(200, 'top-irish-casino-new.png'),
        alt: 'Top Irish Casinos',
        href: 'https://www.topirishcasinos.com/',
        rel: 'noopener noreferrer',
        target: '_blank',
        isDisplayed:
          ['row', 'ae', 'no', 'nz', 'th', 'za'].includes(locale) ||
          (['ca', 'ca-fr'].includes(locale) && !isCanada),
      },
      {
        src: footerImgPath(126, 'pci-dss-compliant-new.png'),
        alt: 'PCI DSS compliant',
        isDisplayed:
          ['ca', 'ca-fr', 'row', 'ae', 'no', 'nz', 'th', 'za', 'at'].includes(locale) || isCanada,
      },
      {
        src: footerImgPath(60, 'age-limit-new.png'),
        alt: '18+ Age Limit',
        isDisplayed:
          ['row', 'ae', 'no', 'nz', 'th', 'za', 'at', 'fi', 'fi-en'].includes(locale) || isMexico,
      },
      {
        src: footerImgPath(126, 'rapid-ssl-new.png'),
        alt: 'Rapid SSL',
        isDisplayed:
          ['ca', 'ca-fr', 'row', 'ae', 'no', 'nz', 'th', 'za', 'at'].includes(locale) ||
          isCanada ||
          isMexico,
      },
      {
        src: footerImgPath(180, 'kahnawake-new.png'),
        alt: 'Kahnawake',
        href: "https://certificates.gamingcommission.ca/KGCCertificates.aspx?id=5b107231-1551-49c2-8396-71cd032fa9c0','BrandNameCertificates','location=0,status=0,scrollbars=1",
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: ['ca', 'ca-fr'].includes(locale) && !isCanada,
      },
      {
        src: footerImgPath(150, 'curacao-logo.jpg'),
        alt: 'Gaming Curacao',
        href: 'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbFZ4U1VGSGVUa3hUVVI2VURnMFJYbFZOSFZwTVZFOVBTSXNJblpoYkhWbElqb2lURlF5VldadFYwVm1VSHBrVTBORlJtWk9RMHRpZHowOUlpd2liV0ZqSWpvaU1EUmtNbUU0WlRZMU16VTFOV1U0WldKbE9XTXhNR0poTWpReU5tWXlaakJoTm1OaFlUSTNNV05qWW1ZMll6ZzFOV0kyWkRFNU16WmpOV00zWkRBM1lTSXNJblJoWnlJNklpSjk=',
        target: '_blank',
        isDisplayed: ['row', 'ae', 'no', 'th', 'za', 'at', 'nz'].includes(locale),
      },
      {
        src: footerImgPath(200, 'stodlinjen.png'),
        alt: 'Stödlinjen',
        href: 'https://www.stodlinjen.se',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: isSweden,
      },
      {
        src: footerImgPath(200, 'spelinspektionen.png'),
        alt: 'Spelinspektionen',
        href: 'https://www.spelinspektionen.se',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: isSweden,
      },
      {
        src: footerImgPath(200, 'mga-new-2023.png'),
        alt: 'Malta Gaming Authority',
        href: 'https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=52a73331-7553-4395-a0a6-10ca8b2423e2&details=1',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: ['fi', 'fi-en'].includes(locale),
      },
      {
        src: footerImgPath(200, 'the-pogg-new.png'),
        alt: 'The Pogg',
        href: 'https://thepogg.com/',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: ['fi', 'fi-en'].includes(locale),
      },
      {
        src: footerImgPath(200, 'gamcare-new.png'),
        alt: 'Gamcare',
        href: 'https://www.gamcare.org.uk/',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: ['fi', 'fi-en'].includes(locale),
      },
      {
        src: footerImgPath(200, 'be-gamble-aware-new.png'),
        alt: 'Be Gamble Aware',
        href: 'https://www.begambleaware.org/',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: ['fi', 'fi-en'].includes(locale),
      },
      {
        src: footerImgPath(200, 'ga-new.png'),
        alt: 'Gamblers Anonymous',
        href: 'http://www.gamblersanonymous.org/ga/',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: ['fi', 'fi-en'].includes(locale),
      },
      {
        src: footerImgPath(180, 'igamingontario.png'),
        alt: 'iGaming Ontario',
        href: 'https://igamingontario.ca/en',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: isCanada,
      },
      {
        src: footerImgPath(50, 'rg-check.png'),
        alt: 'RG Check Accreditation Panel',
        href: 'https://responsiblegambling.org/',
        target: '_blank',
        rel: 'noopener noreferrer',
        isDisplayed: isCanada,
      },
      {
        src: footerImgPath(100, 'age-limit-19.png'),
        alt: '19+ Age Limit',
        isDisplayed: isCanada || ['ca', 'ca-fr'].includes(locale),
      },
      {
        src: footerImgPath(20, 'age-limit-20.png'),
        alt: '20+ Age Limit',
        isDisplayed: isSweden,
      },
      {
        src: cdnImage('SEGOB_Logo.png', {
          path: '/luckydays/images/email',
          width: '180px',
          height: 'auto',
        }),
        alt: 'SEGOB',
        isDisplayed: isMexico,
      },
      {
        src: footerImgPath(120, 'app-store-es.svg'),
        alt: 'App Store',
        href: '/',
        target: '_blank',
        isDisplayed: isMexico && !isInAppWrapper,
      },
      {
        src: footerImgPath(120, 'google-play-es.png'),
        alt: 'Google Play',
        href: '/',
        target: '_blank',
        isDisplayed: isMexico && !isInAppWrapper,
      },
    ];

    return icons.filter(icon => icon.isDisplayed);
  }, [isInAppWrapper, locale, isCanada, isMexico, isSweden, isNormal]);

  return (
    <LazyLoad once height={200} offset={400}>
      <div className="flex flex-wrap justify-around items-center">
        {visibleIcons.map(icon =>
          icon.href ? (
            <a href={icon.href} rel={icon.rel} target={icon.target} key={icon.src}>
              <img
                src={icon.src}
                alt={icon.alt}
                className={classNames('mx-auto w-full max-w-[120px] opacity-60', {
                  '!opacity-100': isCanada || isNormal,
                })}
              />
            </a>
          ) : (
            <div key={icon.src}>
              <img
                src={icon.src}
                alt={icon.alt}
                className={classNames('mx-auto w-full max-w-[120px] opacity-60', {
                  '!opacity-100': isNormal,
                })}
              />
            </div>
          ),
        )}
      </div>
    </LazyLoad>
  );
}

import { Action } from 'redux';
import { OpenedFrom } from '@/lib/gTagManager';

export enum UIActionType {
  SetIsSingleGame = 'UI_SET_IS_SINGLE_GAME',
  SetLoginOpenedFrom = 'UI_SET_LOGIN_OPENED_FROM',
  SetRegistrationOpenedFrom = 'UI_SET_REGISTRATION_OPENED_FROM',
}

export type SetIsSingleGameAction = Action<UIActionType.SetIsSingleGame> & {
  isSingleGame: boolean;
};

export type SetRegistrationOpenedFromAction = Action<UIActionType.SetRegistrationOpenedFrom> & {
  openedFrom: OpenedFrom;
};

export type SetLoginOpenedFromAction = Action<UIActionType.SetLoginOpenedFrom> & {
  openedFrom: OpenedFrom;
};

export type UIActions =
  | SetIsSingleGameAction
  | SetRegistrationOpenedFromAction
  | SetLoginOpenedFromAction;

export const setIsSingleGame = (isSingleGame: boolean): SetIsSingleGameAction => ({
  type: UIActionType.SetIsSingleGame,
  isSingleGame,
});

export const setRegistrationOpenedFrom = (
  openedFrom: OpenedFrom,
): SetRegistrationOpenedFromAction => ({
  type: UIActionType.SetRegistrationOpenedFrom,
  openedFrom,
});

export const setLoginOpenedFrom = (openedFrom: OpenedFrom): SetLoginOpenedFromAction => ({
  type: UIActionType.SetLoginOpenedFrom,
  openedFrom,
});
